<template>
    <div class="page-import-list-item">
        <div class="page-import-list-item-info">

            <span v-for="unit in content.units" class="page-import-list-item-info-description">
                <Collapsible
                    :initialCollapsed="true"
                    class="page-import-list-item-info-description-collapsible"
                >
                    <template #header>
                        <div class="page-import-list-item-info-description-collapsible-header">
                            <h3>{{ unit.title }}</h3>
                            <StatusLabel :config="new StatusLabelConfig({caption: Object.keys(unit.assets).length + ' ' + trans('labels.assets')})" />
                            <StatusLabel
                                v-if="unit.policy !== UnitPermissionPolicyStandard.type"
                                :config="new StatusLabelConfig({type: 'policy', caption: unit.policy})" />
                        </div>
                    </template>

                    <template #body>
                        <textarea
                            :value="Object.values(unit.assets).map(asset => `${trans('assets.types.'+asset.type)}: ${asset.title} [${asset.uid}]`).join('\n')"
                            class="form-control logs"
                            readonly
                            rows="5"
                        ></textarea>
                    </template>
                </Collapsible>
            </span>

            <span class="buttons">
                <ButtonPrimary
                    :caption="isImported ? 'labels.imported' : 'labels.import'"
                    :disabled="isImported"
                    @trigger="onClickImport"
                />

                <h4 class="page-import-list-item-info-title">
                    <Icon v-if="content.units[0].isArchive" name="icon_group" />
                    <Icon v-else name="icon_folder_open" />
                    {{ package }}
                </h4>
            </span>

            <div
                v-if="logs"
                :class="{ error: hasErrors, success: !hasErrors }"
                class="page-import-list-item-command-output"
            >
                <Collapsible
                    class="page-import-list-item-command-output-collapsible"
                >
                    <template #header>
                        <span><b>{{ trans('labels.logs') }}</b></span>
                    </template>

                    <template #body>
                        <textarea
                            :value="logs.join('\n')"
                            class="form-control logs"
                            readonly
                            rows="5"
                        ></textarea>
                    </template>
                </Collapsible>

                <span v-if="Object.values(summary).find(v => v > 0)" v-html="summaryText"></span>
            </div>

        </div>
    </div>

</template>

<script>
import ButtonSecondary from '@/Vue/Common/ButtonSecondary';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary';
import Collapsible from '@/Vue/Common/Collapsible.vue';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import {UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
import {inject} from 'vue';
import {unitServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import Icon from '@/Vue/Common/Icon.vue';
import {trans} from '@/Utility/Helpers.js';

export default {
    name: 'PageImportListItem',
    components: {
        Icon,
        StatusLabel,
        Collapsible,
        ButtonPrimary,
        ButtonSecondary,
    },
    props: {
        content: {
            type: Object,
        },
        package: {
            type: String,
        },
    },
    data() {
        return {
            unitService: inject(unitServiceKey),
            logs: null,
            hasErrors: false,
            summary: {}
        };
    },
    computed: {
        UnitPermissionPolicyStandard() {
            return UnitPermissionPolicyStandard;
        },
        StatusLabelConfig() {
            return StatusLabelConfig;
        },
        isImported() {
            return Object.values(this.content.units).some(unit => unit.exists);
        },
        summaryText() {
            return '<br>' + trans('units.import.summary', {
                unitsImported: this.summary.unitsImported || 0,
                unitsSkipped: this.summary.unitsSkipped || 0,
                unitsFailed: this.summary.unitsFailed || 0,
                assetsImported: this.summary.assetsImported || 0,
                assetsSkipped: this.summary.assetsSkipped || 0,
                assetsFailed: this.summary.assetsFailed || 0
            });
        }
    },
    methods: {
        trans,
        onClickImport() {
            this.hasErrors = false;
            this.logs = null;

            this.unitService.importUnit(this.package)
                .then(logs => {
                    this.logs = logs;
                    this.showImportSummary(logs);
                })
                .catch(error => {
                    this.hasErrors = true;

                    // Handle Validation errors
                    if (error.response && error.response.data) {
                        this.logs = error.response.data.toString().split('\n');
                    } else {
                        this.$root.showErrorDialog(error);
                    }
                });
        },

        showImportSummary(logs) {
            let assetsImported = 0;
            let assetsSkipped = 0;
            let assetsFailed = 0;
            let unitsImported = 0;
            let unitsSkipped = 0;
            let unitsFailed = 0;
            logs.forEach(logEntry => {
                if (logEntry.startsWith('[SUCCESS]: Asset successfully imported')) {
                    assetsImported++;
                } else if (logEntry.startsWith('[SKIP]:')) {
                    assetsSkipped++;
                } else if (logEntry.startsWith('Unable to import asset')) {
                    assetsFailed++;
                } else if (logEntry.startsWith('Unit successfully imported')) {
                    unitsImported++;
                } else if (logEntry.startsWith('Skipping existing unit:')) {
                    unitsSkipped++;
                } else if (logEntry.startsWith('Unable to import because unit uses missing assets.')) {
                    unitsFailed++;
                }
            });

            this.summary = {
                assetsImported,
                assetsSkipped,
                assetsFailed,
                unitsImported,
                unitsSkipped,
                unitsFailed,
            };

            if (unitsFailed || assetsFailed) {
                this.$toast.error(trans('units.import.failed'));
            } else {
                this.$toast.success(trans('units.import.success'));
            }
        }
    }
};
</script>

<style lang="scss">

.page-import-list-item {
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    transition: box-shadow 0.15s ease-in-out;

    padding: 20px;

    .page-import-list-item-info-title {
        color: var(--color-anthracite30);
        align-self: center;

        svg {
            width: 24px;
            height: 24px;
            margin: -3px 2px 0 10px;
        }
    }

    textarea.form-control.logs {
        min-height: 300px;
    }

    .buttons {
        margin-top: var(--typo-spacing-default);
    }

    .page-import-list-item-info-description {
        & > span {
            display: block;
        }
    }

    .page-import-list-item-info-description-collapsible-header {
        display: flex;
        align-items: center;
        gap: 10px;

        h3 {
            margin: 0;
        }
    }

    .page-import-list-item-info-description-collapsible,
    .page-import-list-item-command-output-collapsible {
        .collapsible-header {
            padding: 8px var(--sidepanel-padding) 8px 22px;
        }

        .collapsible-header::before {
            left: 6px;
        }
    }

    textarea {
        font-family: var(--font-family-mono-regular);
        font-size: var(--font-size-small);
        white-space: pre;
        padding: 8px;
        min-height: 120px;
    }

    & + .page-import-list-item {
        margin-top: 20px;
    }

    .page-import-list-item-command-output {
        margin-top: 20px;

        &.error {
            textarea {
                border-color: var(--color-signal);
            }
        }

        &.success {
            textarea {
                border-color: var(--color-item-green);
            }
        }
    }
}

</style>
