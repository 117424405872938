<template>

    <main id="layout-main" :class="{embed: embed}">

        <PageHeader :page-title="unitTitle" :show-back-button="showBackButton">
            <template v-if="embed" #icon>
                <a
                    :title="logoTitle"
                    class="logo-link"
                    href="https://3spin-learning.com"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <svg>
                        <use xlink:href="#logo_3spin_Learning_hat" />
                    </svg>
                </a>
            </template>
        </PageHeader>

        <div id="layout-content" ref="layoutContent">

            <div class="column-left">
                <div
                    ref="preview"
                    :class="{ 'hidden': !(unitPreview && !showCanvas), 'show-start-webapp-button': !startUnitOnLoad }"
                    class="unit-preview"
                    @click="onStartApp"
                >
                    <img
                        :src="unitPreview"
                        alt=""
                        class="unit-preview-image"
                    >
                    <Icon class="start-webapp-button" name="icon_play" />
                    <div v-if="loading" class="loading">
                        <loading-indicator />
                    </div>
                </div>

                <canvas id="unity-canvas" ref="unityCanvas" />

                <nav class="button-bar">
                    <ButtonPrimary
                        v-if="(showCanvas || startUnitOnLoad) && !loading"
                        caption="labels.fullscreen"
                        icon="icon_fullscreen"
                        @trigger="onTriggerFullscreen"
                    />
                </nav>
            </div>

            <aside v-if="shouldShowControls" class="controls">
                <section>
                    <h3>{{ trans('units.launch.controls.headline') }}</h3>
                    <img
                        :alt="trans('units.launch.controls.instruction_menu_alt')"
                        src="/svg/controls_open-menu.svg"
                    >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class="instruction" v-html="trans('units.launch.controls.instruction_menu')" />
                </section>
                <section>
                    <img
                        :alt="trans('units.launch.controls.instruction_click_alt')"
                        src="/svg/controls_click.svg"
                    >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class="instruction" v-html="trans('units.launch.controls.instruction_focus')" />
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class="instruction" v-html="trans('units.launch.controls.instruction_click')" />
                </section>
                <section>
                    <img
                        :alt="trans('units.launch.controls.instruction_mode_alt')"
                        src="/svg/controls_switch-mode.svg"
                    >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class="instruction" v-html="trans('units.launch.controls.instruction_mode')" />
                </section>
                <section>
                    <img
                        :alt="trans('units.launch.controls.instruction_move_alt')"
                        src="/svg/controls_movement.svg"
                    >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class="instruction" v-html="trans('units.launch.controls.instruction_move')" />
                </section>
                <section>
                    <img
                        :alt="trans('units.launch.controls.instruction_exit_fullscreen_alt')"
                        src="/svg/controls_exit-fullscreen.svg"
                    >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class="instruction" v-html="trans('units.launch.controls.instruction_exit_fullscreen')" />
                </section>
                <section>
                    <p class="help">
                        <Icon name="icon_help-center" />
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="trans('units.launch.controls.help')" />
                    </p>
                </section>
            </aside>

            <ModalProgress />
            <ModalNotification />
        </div>
    </main>
</template>

<script lang="ts">

declare global {
    // noinspection JSUnusedGlobalSymbols
    /**
     * Make properties of unity loader on window scope known to typescript.
     */
    interface Window {
        getUnitLaunchData: (() => string) | undefined;

        navigateBack: (() => void) | undefined;

        MsCognitiveSpeechSdk: typeof MsCognitiveSpeechSdk | undefined;

        createUnityInstance: ((
            canvas: HTMLCanvasElement,
            config: any,
            onLoadProgress: (number: number) => void
        ) => Promise<UnityInstance>) | undefined;
    }
}

import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import EventType from '@/Utility/EventType';
import {trans} from '@/Utility/Helpers';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import Icon from '@/Vue/Common/Icon.vue';
import {defineComponent, ref, watch} from 'vue';
import {useResize} from '@/Vue/Utility/useResize';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import * as MsCognitiveSpeechSdk from 'microsoft-cognitiveservices-speech-sdk';
import type {UnityInstance} from '@/Models/Unity/UnityInstance';

export default defineComponent({

    components: {
        ModalProgress,
        LoadingIndicator,
        Icon,
        ButtonPrimary,
        PageHeader,
        ModalNotification,
    },

    props: {
        courseUid: {
            type: String,
            default: null,
            required: false,
        },
        unitUid: {
            type: String,
            required: true,
        },
        unitRevisionUid: {
            type: String,
            required: true,
        },
        unitTitle: {
            type: String,
            required: true,
        },
        unitPreview: {
            type: String,
            default: null,
        },
        accessToken: {
            type: String,
            required: true,
        },
        buildFilesBaseName: {
            type: String,
            default: '3spinLearning'
        },
        aspectRatio: {
            type: Number,
            default: 16 / 9
        },
        embed: {
            type: Boolean,
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: true,
        },
        startUnitOnLoad: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            unityInstance: null as UnityInstance | null,
            showCanvas: false,
            loading: false,
        };
    },

    computed: {

        buildUrl() {
            return `/storage/players/${this.releaseVersion}/3spinLearning`;
        },

        releaseVersion() {
            return window.appData.APP_RELEASE;
        },

        streamingAssetsUrl() {
            return `/storage/players/${this.releaseVersion}/StreamingAssets`;
        },

        logoTitle() {
            return window.appData.APP_TITLE;
        },

        shouldShowControls() {
            return this.showCanvas || this.startUnitOnLoad || !this.embed;
        },

        unityCanvasElement() {
            return this.$refs.unityCanvas as HTMLCanvasElement;
        },

        previewElement() {
            return this.$refs.preview as HTMLDivElement;
        },

        layoutElement() {
            return this.$refs.layoutContent as HTMLDivElement;
        },
    },

    mounted() {
        window.getUnitLaunchData = () => JSON.stringify({
            course_uid: this.courseUid,
            unit_uid: this.unitUid,
            unit_revision_uid: this.unitRevisionUid,
            tenant_uid: window.currentUser?.tenant?.uid,
            access_token: this.accessToken,
        });

        window.navigateBack = () => {
            if (this.showBackButton) {
                window.history.back();
            }
        };

        window.MsCognitiveSpeechSdk = MsCognitiveSpeechSdk;

        if (this.startUnitOnLoad) {
            this.startApp();
        }

        const layoutContent = ref(this.layoutElement);
        const { width, height } = useResize(layoutContent);

        watch([width, height], () => {
            this.onResizeContent(width.value, height.value);
        });

        document.addEventListener('fullscreenchange', this.onChangeFullscreenMode);
    },

    beforeUnmount() {
        document.removeEventListener('fullscreenchange', this.onChangeFullscreenMode);
    },

    methods: {
        trans,

        startApp() {
            this.loading = true;

            // attach loader script
            const script = document.createElement('script');
            script.src = this.getBuildFileUrl('loader.js');
            script.onload = this.onLoaderScriptLoaded;
            document.body.appendChild(script);
        },

        onLoaderScriptLoaded() {
            const config = {
                dataUrl: this.getBuildFileUrl('data.gz'),
                frameworkUrl: this.getBuildFileUrl('framework.js.gz'),
                codeUrl: this.getBuildFileUrl('wasm.gz'),
                streamingAssetsUrl: this.streamingAssetsUrl,
                showBanner: this.onUnityError,
                matchWebGLToCanvasSize: false,
            };

            if (!window.createUnityInstance) {
                throw new Error('createUnityInstance is not defined');
            }

            window.createUnityInstance(this.unityCanvasElement, config, this.onLoadProgress)
                .then(this.onLoadFinished)
                .catch(this.onLoadError)
                .finally(() => {
                    this.showCanvas = true;
                    this.loading = false;
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                });
        },

        onLoadProgress(progress: number) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'), progress);
        },

        onLoadFinished(unityInstance: UnityInstance) {
            this.unityInstance = unityInstance;
        },

        onLoadError(message: string) {
            this.$root!.showErrorDialog(message);
        },

        onUnityError(message: string, type: 'error' | 'warning') {
            switch (type) {
                case 'error':
                    this.$root!.showErrorDialog(message);
                    break;
                case 'warning':
                    console.warn('onUnityError', message, type);
                    break;

            }
        },

        onStartApp() {
            if (!this.startUnitOnLoad) {
                this.startApp();
            }
        },

        onTriggerFullscreen() {
            this.unityInstance?.SetFullscreen(1);
        },

        onChangeFullscreenMode() {
            if (document.fullscreenElement !== null) {
                this.unityCanvasElement.classList.add('fullscreen');
            } else {
                this.unityCanvasElement.classList.remove('fullscreen');
            }
        },

        /**
         * @return path of the file with the given extension inside the build directory
         */
        getBuildFileUrl(extension: string): string {
            return `${this.buildUrl}/${this.buildFilesBaseName}.${extension}`;
        },

        onResizeContent(contentWidth: number, contentHeight: number) {
            const isInFullscreenMode = (document.fullscreenElement !== null);
            const availableWidth = isInFullscreenMode ? window.innerWidth : (contentWidth - 250 - 16); // - controls - gap
            const availableHeight = isInFullscreenMode ? window.innerHeight : (contentHeight - 35 - 8); // - buttonbar - gap
            const availableAspect = availableWidth / availableHeight;
            const desiresAspect = isInFullscreenMode ? availableAspect : this.aspectRatio;

            if (availableAspect > desiresAspect) {
                // available space is wider than desired - use available height for canvas
                this.resizeRef(this.unityCanvasElement, availableHeight * desiresAspect, availableHeight);
                this.resizeRef(this.previewElement, availableHeight * desiresAspect, availableHeight);

            } else {
                // available space is taller than desired - use available width for canvas
                this.resizeRef(this.unityCanvasElement, availableWidth, availableWidth / desiresAspect);
                this.resizeRef(this.previewElement, availableWidth, availableWidth / desiresAspect);
            }
        },

        resizeRef(ref: HTMLCanvasElement | HTMLDivElement, finalWidth: number, finalHeight: number) {
            const element = ref;

            if (element instanceof HTMLCanvasElement) {
                // render size for unity (it may be larger than browser size)
                element.width = finalWidth * window.devicePixelRatio;
                element.height = finalHeight * window.devicePixelRatio;
            }

            // display size for the browser
            element.style.width = Math.round(finalWidth) + 'px';
            element.style.height = Math.round(finalHeight) + 'px';
        },
    },
});
</script>

<style lang="scss" scoped>

.logo-link {
    height: 40px;
    aspect-ratio: 16 / 12;
    margin-right: 5px;
    margin-left: -5px;
    margin-top: 4px;

    svg {
        width: 100%;
        height: 100%;
    }
}

#layout-main.embed {
    #layout-header,
    #layout-content {
        padding-left: 16px;
    }
}

#layout-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 8px 16px 16px 50px;
}

.column-left {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.unit-preview {
    border-radius: var(--card-border-radius-small);
    background-color: white;
    position: relative;
    overflow: hidden;

    &:not(.hidden) + canvas {
        display: none;
    }

    &.hidden {
        display: none;
    }

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .unit-preview-image {
        width: 100%;
        height: 100%;
    }

    .start-webapp-button {
        position: absolute;
        height: 20%;
        width: 20%;
        top: calc(50% - calc(20% / 2));
        left: calc(50% - calc(20% / 2));
        color: white;
        display: none;
        transition: color .1s;
    }

    &.show-start-webapp-button {
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            transition: background-color .1s;
        }

        &:hover {
            &:before {
                background-color: rgba(0, 0, 0, 0.3);
            }

            .start-webapp-button {
                color: var(--color-primary-hover);
            }
        }

        .start-webapp-button {
            display: block;
        }
    }


}

canvas {
    img {
        width: 100%;
        height: auto;
    }
}

canvas:not(.fullscreen) {
    border-radius: var(--card-border-radius-small);
    background-color: white;
}

canvas.fullscreen {
    background-color: black;
}

.button-bar {
    display: flex;
    justify-content: flex-end;
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-basis: 250px;
    flex-shrink: 0;
    padding: 16px 24px;
    background-color: white;
    border-radius: var(--card-border-radius-small);
    align-self: start;
    max-height: 100%;
    overflow-y: auto;

    section {

        h3 {
            font-size: var(--font-size-default);
        }

        img {
            margin-bottom: 8px;
        }

        .instruction, .help {
            font-size: var(--font-size-small);
            font-family: var(--font-family-condensed);
            margin: 0;

            ::v-deep(em) {
                font-family: var(--font-family-condensed-demibold);
                font-style: normal;
                font-weight: normal;
            }

            .icon {
                width: 18px;
                height: 18px;
                margin: 0 4px;
            }
        }

        .help {
            font-family: var(--font-family-condensed-demibold);

            span {
                vertical-align: middle;
            }
        }
    }
}

</style>
