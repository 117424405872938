<template>
    <Collapsible class="inspector-panel panel-variables" v-shortcuts>

        <template #header>
            {{ trans('labels.variables') }}
        </template>

        <template #body>
            <!-- Variable selection -->
            <template v-if="sceneObject.hasVariables">
                <div class="sceneobject-variable-selection">
                <!-- Settings for each variable -->
                    <Draggable
                        v-for="(variable, index) in sceneObject.variables"
                        groups="panel-variables"
                        :disabled="(sceneObject.variables.length < 2)"
                        :value="index"
                        :draggable-selector="'header[draggable]'"
                        @drop="onDropVariable"
                        class="panel-card sceneobject-variable"
                        :key="'variable_settings'+variable.uid+variable.type+index+'_'+sceneObject.variables.length"
                        v-focusable
                        :data-variable-uid="variable.uid"
                    >
                        <header :draggable="(sceneObject.variables.length >= 2)">
                            <Icon v-if="!variable.isValid" name="icon_invalid" class="icon-invalid" />
                            <label>{{ trans('variables.' + variable.type) }}</label>
                            <Icon name="icon_close" class="icon-delete" @click="onClickRemoveVariable(variable)" />
                        </header>

                         <PanelVariable
                            :variable="variable"
                            @change="onChangeVariable"
                        />
                    </Draggable>
                </div>
            </template>

            <!-- Dropdown for new/unassigned variable -->
            <div v-if="showNewVariableSelection" class="btn-add-with-dropdown">
                <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
                <ButtonSecondary v-not-focusable icon="icon_add" caption="labels.add_variable" />
                <Dropdown
                    :key="'dropdown-'+sceneObject.uid+'_'+sceneObject.variables.length"
                    :options="getVariableOptions()"
                    @select="onSelectVariable"
                />
            </div>
        </template>
    </Collapsible>
</template>

<script>
// Import VueJS components:
import OverlayConfirmRemove     from '@/Vue/Inspector/OverlayConfirmRemove.vue';
import PanelVariable            from '@/Vue/Inspector/Variables/PanelVariable.vue';

// Import classes:
import DropdownOption                   from '@/Utility/DropdownOption';
import { sortArrayByProperty, trans }   from '@/Utility/Helpers';
import { SceneObjectModuleVariable }    from '@/Models/UnitData/SceneObjects/SceneObject';
import VariableFactory                  from '@/Models/UnitData/Variables/Variables/VariableFactory';

export default {
    name: 'PanelVariables',
    emits: [
        'change',
    ],
    components: {
        PanelVariable,
        OverlayConfirmRemove
    },
    props: {
        sceneObject: {                  // Selected SceneObject that the variables are assigned to
            type: SceneObjectModuleVariable,
            default: null
        }
    },
    data() {
        return {
            shortcuts: new Map([
                ['Delete', this.onShortcutDelete],          // Delete variable
                ['Duplicate.stop.prevent', null],           // Prevent duplicate shortcut from bubbling
                ['Cut.stop.prevent', null],                 // Prevent cut shortcut from bubbling
                ['Copy.stop.prevent', null],                // Prevent copy shortcut from bubbling
                ['Paste.stop.prevent', null],               // Prevent paste shortcut from bubbling
            ]),
        }
    },
    computed: {

        /**
         * Whether to show the selection for adding a new variable
         *
         * @returns {Boolean}
         */
        showNewVariableSelection() {
            return (this.sceneObject.variables.length < SceneObjectModuleVariable.MaxAllowedVariables);
        }
    },
    methods: {

        /**
         * Transform the variable types to options for the dropdown
         *
         * @returns {DropdownOption[]}
         */
        getVariableOptions() {
            const variableTypes = (this.sceneObject !== null) ? this.sceneObject.supportedVariableTypes : null;

            if (!(variableTypes instanceof Array)) {
                return [];
            }

            const options = variableTypes.map(type => new DropdownOption({
                caption: trans('variables.' + type),
                value: type
            }));

            return sortArrayByProperty(options, 'caption', false);
        },

        /**
         * Select handler for the variable dropdown
         *
         * @param {String} value
         */
        onSelectVariable(value) {
            const variable = VariableFactory.createDefaultVariableWithType(value, this.sceneObject);
            this.sceneObject.variables.push(variable);

            this.$emit('change', this.sceneObject);

            return this;
        },

        /**
         * Click handler for variable remove button
         *
         * @param {Variable} variable   // Variable object reference
         */
        onClickRemoveVariable(variable) {
            this.sceneObject.removeVariable(variable);
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Change handler for the variable settings
         *
         * @param {Variable} variable
         */
        onChangeVariable(variable) {
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Drop handler for variables
         *
         * @param {MouseEvent} e
         */
        onDropVariable(e) {
            e.preventDefault();
            if (!(e.dataDraggable instanceof Object) || !(e.dataDropTarget instanceof Object)) {
                console.warn('PanelVariables->onDropVariable(): Invalid dragging data.', e);
                return this;
            }

            const currentIndex = e.dataDraggable?.value || 0;
            const targetIndex = e.dataDropTarget?.value || 0;

            // Source and target variables must not be the same:
            let hasChanged = false;
            if (currentIndex !== targetIndex)
            {
                if (targetIndex > currentIndex)
                {
                    this.sceneObject.variables.splice(targetIndex + 1, 0, this.sceneObject.variables[currentIndex]);
                    this.sceneObject.variables.splice(currentIndex, 1);
                    hasChanged = true;
                }
                else if (targetIndex < currentIndex)
                {
                    this.sceneObject.variables.splice(targetIndex, 0, this.sceneObject.variables[currentIndex]);
                    this.sceneObject.variables.splice(currentIndex + 1, 1);
                    hasChanged = true;
                }
                //console.log('onDrop', e, currentIndex, targetIndex);
            }
            if (hasChanged === true)
            {
                this.$emit('change', this.sceneObject);
            }
            return this;
        },

        /**
         * Shortcut handler: Delete
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            const variableUid = e.target.dataset.variableUid || null;
            if (variableUid !== null)
            {
                e.stopImmediatePropagation();
                e.detail.keyboardEvent.stopImmediatePropagation();
                const variable = this.sceneObject.variables.find(v => v.uid === variableUid) || null;
                if (variable !== null)
                {
                    return this.onClickRemoveVariable(variable);
                }
            }
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
