<template>
    <div class="command-settings">
        <FeatureNotAvailable :data-object="command"/>

        <!-- Select target -->
        <Dropdown
            :initial-value="command.target"
            @select="onSelectTarget"
            :options="getTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <TextInput
            :model="command"
            property="knowledge"
            :required="!command.isValid"
            type="textarea"
            :maxlength="1600"
            :placeholder="trans('commands.ai.knowledge.placeholder')"
            @change="onChangeCommand"
        />

        <Dropdown
            :model="command"
            property="knowledge_type"
            :options="getDropdownOptionsForKnowledgeType"
            :required="true"
            @select="onChangeCommand"
        />
    </div>
</template>

<script>
    import {AIKnowledgeCommand} from '@/Models/UnitData/Commands/Command';
    import TextInput from "@/Vue/Common/TextInput.vue";
    import {getSceneObjectTargetDropdownOptionsForCommand} from "@/Models/UnitData/Commands/CommandHelpers";
    import FeatureNotAvailable from "@/Vue/Features/FeatureNotAvailable.vue";
    import {trans} from "@/Utility/Helpers/trans";
    import DropdownOption from "@/Utility/DropdownOption";
    import Dropdown from "@/Vue/Common/Dropdown.vue";
    import {AIKnowledgeTypeHelpers} from "@/Models/UnitData/Commands/AIKnowledgeType";

    export default {
        name: 'PanelCommandAIKnowledge',

        components: {
            Dropdown,
            FeatureNotAvailable,
            TextInput,
        },

        emits: [
            'change',
        ],

        props: {
            command: {
                type: AIKnowledgeCommand,
                default: null
            }
        },

        computed: {
            /**
             * Get options for target scene object selection dropdown
             *
             * @returns {DropdownOption[]}
             */
            getTargetDropdownOptionsForCommand() {
                return getSceneObjectTargetDropdownOptionsForCommand(this.command);
            },

            /**
             * @returns {DropdownOption[]}
             */
            getDropdownOptionsForKnowledgeType() {
                return AIKnowledgeTypeHelpers.all().map(knowledgeType => {
                    return new DropdownOption({
                        caption: AIKnowledgeTypeHelpers.caption(knowledgeType),
                        value: knowledgeType,
                        icon: AIKnowledgeTypeHelpers.icon(knowledgeType),
                    });
                });
            }
        },

        methods: {
            trans,

            /**
             * Select handler for target 3d object dropdown
             *
             * @param {String} targetUID
             */
            onSelectTarget(targetUID) {
                if (targetUID !== this.command.target) {
                    this.command.target = targetUID;
                    this.$emit('change', this.command);
                }
                return this;
            },

            /**
             *  Change handler for the command
             */
            onChangeCommand() {
                this.$emit('change', this.command);
                return this;
            },
        },
    }
</script>
