import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import CoursePermissionPolicy from '@/Models/Course/CoursePermissionPolicy';
import {TenantRole} from "@/Models/Tenant/TenantRole";
import type Course from "@/Models/Course/Course";
import type User from "@/Models/User/User";
import type Unit from "@/Models/Unit/Unit";

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.CourseUpdate() = 'courses:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('Course', new CoursePolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.CourseUpdate()), course);
 *
 */
export default class CoursePolicy {

    /**
     * Determine whether the user can delete the training.
     */
    delete(user: User, course: Course): boolean {
        return (
            this.courseIsInScopeForWriting(user, course) &&
            permission(Permission.CoursesDelete())
        );
    }

    /**
     * Determine whether the user can read the course.
     */
    read(user: User, course: Course): boolean {
        return (
            this.courseIsInScopeForReading(user, course) &&
            permission(Permission.CoursesRead())
        );
    }

    /**
     * Determine whether the user can access the unit based on his course enrollments.
     * The unit must be referenced in the course.
     * A user must be enrolled in the course that uses the unit revision.
     *
     * @param  {User}  user
     * @param  {Course}  course
     * @param  {Unit}  unit
     * @return {boolean}
     */
    launch_unit(user: User, course: Course, unit: Unit): boolean {
        const unitIsReferencedInCourse = course.unit_uids.includes(unit.uid);

        return (
            unitIsReferencedInCourse
            && unit.isReleased
            && unit.latestReleasedRevision!.isCompatible
            && permission(Permission.CoursesLaunchUnit())
            && this.courseIsInScopeForReading(user, course)
        );
    }

    /**
     * Determine whether the user can update the course.
     */
    update(user: User, course: Course): boolean {
        return (
            this.courseIsInScopeForWriting(user, course) &&
            permission(Permission.CoursesUpdate())
        );
    }

    /**
     * Determine whether the user can (auto) enroll users in the course.
     */
    enroll_users(user: User, course: Course): boolean {
        // Do not allow enrollment changes for some policies
        if (course.parsedPolicy?.requiresAutoEnrollment) {
            return false;
        }

        return (
            (
                user.uid === course.owned_by &&
                permission(Permission.CoursesEnrollUsersInOwn())
            ) ||
            permission(Permission.CoursesEnrollUsersInAny())
        );
    }

    courseIsInScopeForWriting(user: User, course: Course): boolean {
        // Deny access to the course if it is not owned by the current tenant
        if (course.owned_by_tenant !== user.tenant?.uid) {
            return false;
        }

        const availablePolicies = CoursePermissionPolicy
            .allAvailablePoliciesForUser(user)
            .map(permissionPolicyMapping => permissionPolicyMapping[1]);

        const userHasAccessToPolicy = availablePolicies.some(policy => policy.type === course.policy);

        if (!userHasAccessToPolicy) {
            return false;
        }

        // TODO: Replace these tenant-Role checks with a proper Permission
        // Tenant admins and authors can access all courses on the tenant
        if (user.tenant_role?.isAnyOf(TenantRole.Name.Author, TenantRole.Name.Admin)) {
            return true;
        }

        return false;
    }

    courseIsInScopeForReading(user: User, course: Course): boolean {
        // TODO: Replace these tenant-Role checks with a proper Permission
        // Tenant admins and authors can access all courses on the tenant
        if (user.tenant_role?.isAnyOf(TenantRole.Name.Author, TenantRole.Name.Admin)) {
            return true;
        }

        // Is enrolled?
        return course.auto_enrollment || course.isEnrolled(user);
    }
}
