<template>
    <div id="inspector" class="inspector-content" v-not-focusable>
        <header class="inspector-header">
            <Icon name="icon_units" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.unit_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div class="panels" v-not-focusable :key="'InspectorPanels' + renderTimestamp">
            <div class="inspector-panel panel-unit" v-if="unitRevision" :key="'PanelUnit'+unitRevision.type">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    :src="unitRevision.preview"
                    :editable="canEditUnit"
                    @change="onChangePreviewImage"
                />

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :disabled="!canEditUnit"
                        :model="unitData"
                        property="title"
                        @change="onChangeTitle"
                        :required="true"
                        :maxlength="50"
                        :placeholder="trans('units.create.unit_title_placeholder')"
                        :error-msg="trans('errors.unit.title')"
                    />
                </div>

                <!-- Type -->
                <div class="property property-type">
                    <Dropdown
                        ref="dropdownUnitType"
                        class="no-wrap"
                        :disabled="!canEditUnit"
                        :key="'dropdownUnitType'+renderTimestamp"
                        :label="trans('labels.type')"
                        :initial-value="unitData.type"
                        @select="onChangeType"
                        :options="getOptionsForType"
                    />
                </div>

                <!-- Policy -->
                <div class="property property-policy">
                    <Dropdown
                        class="no-wrap"
                        :disabled="!canEditUnit || !canEditPolicy"
                        :key="'dropdownUnitPolicy'+renderTimestamp"
                        :label="trans('labels.policy')"
                        :model="unit"
                        property="policy"
                        @select="onChangePolicy"
                        :options="getOptionsForPolicy"
                    />
                </div>

                <!-- Spatial positioning -->
                <div class="property property-spatial-positioning">
                    <Checkbox
                        :disabled="isSpatialPositioningDisabled"
                        :model="unitData"
                        property="spatial_positioning"
                        layout="checkbox-right"
                        @change="onChangeUnitData"
                        :caption="trans('authoring.spatial_positioning')"
                    />
                </div>

                <!-- App Panel -->
                <div class="property property-app-panel">
                    <Checkbox
                        :disabled="!canEditUnit"
                        :model="unitData.configuration.app_panel"
                        property="show_app_panel"
                        layout="checkbox-right"
                        @change="onChangeUnitData"
                        :caption="trans('authoring.show_app_panel')"
                    />
                </div>

                <!-- Colors -->
                <div class="property-group property-colors">
                    <header>{{ trans('labels.colors') }}</header>
                    <ColorHexInput
                        v-for="(color, colorIndex) in unitData.configuration.colors"
                        class="property property-color"
                        :disabled="!canEditUnit"
                        :key="'unitcolorhex'+colorIndex"
                        :model="unitData.configuration.colors"
                        :property="colorIndex"
                        :label="trans('labels.' + colorIndex)"
                        @change="onChangeUnitData"
                    />
                </div>

                <!-- Input -->
                <div class="property property-input">
                    <Dropdown
                        :disabled="!canEditUnit"
                        class="no-wrap"
                        :label="trans('labels.input_style')"
                        :model="unitData.configuration.input"
                        property="style"
                        @select="onChangeUnitData"
                        :options="getOptionsForInput"
                    />
                </div>

                <!-- Network -->
                <div class="property-group property-network">
                    <header>{{ trans('labels.network') }}</header>

                    <div class="property property-network-avatars-visible">
                        <Checkbox
                            :disabled="!canEditUnit"
                            :model="unitData.configuration.network"
                            property="avatars_visible"
                            layout="checkbox-right"
                            @change="onChangeUnitData"
                            :caption="trans('authoring.network_avatars_visible')"
                        />
                    </div>
                    <div class="property property-network-user-join-muted">
                        <Checkbox
                            :disabled="!canEditUnit"
                            :model="unitData.configuration.network"
                            property="users_join_muted"
                            layout="checkbox-right"
                            @change="onChangeUnitData"
                            :caption="trans('authoring.network_users_join_muted')"
                        />
                    </div>
                    <div class="property property-network-control-mode">
                        <Dropdown
                            class="no-wrap"
                            :label="trans('labels.mode')"
                            :disabled="!canEditUnit"
                            :model="unitData.configuration.network"
                            property="control_mode"
                            @select="onChangeUnitData"
                            :options="getOptionsForNetworkControlMode"
                        />
                        <p v-if="unitData.configuration.network.control_mode" v-html="trans('networkcontrolmodes.' + unitData.configuration.network.control_mode + '_description')"></p>
                    </div>
                </div>

                <!-- Description -->
                <div class="property property-description">
                    <TextInput
                        :disabled="!canEditUnit"
                        type="textarea"
                        :label="trans('labels.description')"
                        :model="unitData"
                        property="description"
                        @change="onChangeDescription"
                        :required="false"
                        :maxlength="600"
                        :placeholder="trans('units.create.unit_description_placeholder')"
                    />
                </div>

                <!-- Author assignments -->
                <PanelAuthorAssignment
                    v-if="showAuthorAssignment && canAccessUsers"
                    :training="unit"
                />

                <!-- Modal dialogs -->
                <ModalChangeTrainingType />
                <ModalApplyCancel
                    event-type="MODAL_REMOVE_AUTHORS_FROM_UNIT"
                    :title="trans('modals.remove_authors_from_unit.title')"
                    :description="trans('modals.remove_authors_from_unit.description')"
                />
            </div>
        </div>


    </div>
</template>

<script>
    // Import VueJS components:
    import OverlayConfirmRemove from '@/Vue/Inspector/OverlayConfirmRemove.vue';
    import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
    import ModalChangeTrainingType from '@/Vue/Modals/ModalChangeUnitType.vue';
    import InspectorItemPreviewImage from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
    import PanelAuthorAssignment from '@/Vue/Inspector/PanelAuthorAssignment.vue';

    // Import classes:
    import {permission, sortArrayByProperty, trans} from '@/Utility/Helpers';
    import EventType from '@/Utility/EventType';
    import DropdownOption from '@/Utility/DropdownOption';
    import NetworkControlMode from '@/Models/UnitData/Configuration/NetworkControlMode';
    import {Permission} from '@/Models/User/Permission';
    import TrainingInput from '@/Models/UnitData/TrainingInput';
    import UnitRevision from '@/Models/Unit/UnitRevision';
    import UnitType from '@/Models/UnitData/UnitType';
    import {featureRepositoryKey} from "@/Vue/Bootstrap/InjectionKeys";
    import {Feature} from "@/Models/Features/Feature";
    import UnitPermissionPolicy, {UnitPermissionPolicyTemplate} from '@/Models/Unit/UnitPermissionPolicy';
    import {inject} from "vue";
    import Unit from "@/Models/Unit/Unit";

    export default {
        name: 'SidepanelEditUnitRevision',
        emits: [
            'change',
        ],
        components: {
            ModalChangeTrainingType,
            ModalApplyCancel,
            OverlayConfirmRemove,
            InspectorItemPreviewImage,
            PanelAuthorAssignment,
        },
        props: {
            unitRevision: {
                type: UnitRevision,
                default: null,
            },
            showAuthorAssignment: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                unitTypes: UnitType.all,                        // List of unit types for creating the selection buttons
                renderTimestamp: null,                          // Helper for forcing re-rendering

                /**
                 * @type FeatureRepository
                 */
                featureRepository: inject(featureRepositoryKey),
            }
        },
        computed: {

            /**
             * @returns {Unit|null}
             */
            unit() {
                return this.unitRevision?.getParent(Unit) || null;
            },

            /**
             * @returns {UnitData|null}
             */
            unitData() {
                return this.unitRevision?.unit_data || null;
            },

            /**
             * @returns {Boolean}
             */
            canAccessUsers() {
                return permission(Permission.UsersRead());
            },

            /**
             * Check if the user has the necessary permissions to edit the unit policy
             *
             * @returns {boolean}
             */
            canEditPolicy() {
                return this.getOptionsForPolicy.some(
                    option => (
                        this.unit.policy === option.value
                        && !option.disabled
                    )
                );
            },

            /**
             * @returns {Boolean}
             */
            canEditUnit() {
                return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.unit);
            },

            /**
             * Options for Type dropdown
             *
             * @returns {DropdownOption[]}
             */
            getOptionsForType() {
                const options = UnitType.all.map(
                    t => new DropdownOption({
                        caption: t.title,
                        value: t.type
                    })
                );
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Options for Input dropdown
             *
             * @returns {DropdownOption[]}
             */
            getOptionsForInput() {
                const options = TrainingInput.all.map(
                    t => new DropdownOption({
                        caption: t.title,
                        value: t.type
                    })
                );
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Options for NetworkControlMode dropdown
             *
             * @returns {DropdownOption[]}
             */
            getOptionsForNetworkControlMode() {
                const options = NetworkControlMode.all.map(
                    m => new DropdownOption({
                        caption: m.title,
                        value: m.type
                    })
                );
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Options for Policy dropdown
             *
             * @returns {DropdownOption[]}
             */
            getOptionsForPolicy() {
                const options = UnitPermissionPolicy
                    .getPermissionPolicyMapping()
                    .filter(([, policy]) => {
                        return policy instanceof UnitPermissionPolicy;
                    })
                    .map(([policyPermission, policy]) => {
                        // some policies are only allowed in default assert tenant
                        const forbiddenInTenant = policy.hasToExistInsideAssetDefaultTenant &&
                            !window.currentUser.tenant.is_default_asset_tenant;

                        // policy is not allowed for user
                        const forbiddenForUser = !permission(policyPermission);

                        return new DropdownOption({
                            caption: trans('unit_policies.' + policy.constructor.type),
                            value: policy.constructor.type,
                            disabled: forbiddenInTenant || forbiddenForUser,
                        })
                    });
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Is spatial positioning disabled?
             *
             * @returns {Boolean}
             */
            isSpatialPositioningDisabled() {
                return !this.canEditUnit || this.unit.type === UnitType.AR.type;
            },
        },
        mounted() {
            this.$globalEvents.on(EventType.INSPECTOR_INSPECT, this.refresh);
            this.$globalEvents.on(EventType.MODAL_CHANGE_UNIT_TYPE_APPLY, this.onApplyChangeUnitType);
            this.$globalEvents.on(EventType.MODAL_CHANGE_UNIT_TYPE_CANCEL, this.onCancelChangeUnitType);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.INSPECTOR_INSPECT, this.refresh);
            this.$globalEvents.off(EventType.MODAL_CHANGE_UNIT_TYPE_APPLY, this.onApplyChangeUnitType);
            this.$globalEvents.off(EventType.MODAL_CHANGE_UNIT_TYPE_CANCEL, this.onCancelChangeUnitType);
        },
        methods: {

            /**
             * Refresh the UI
             */
            refresh() {
                // Force re-render of the component:
                this.renderTimestamp = (new Date()).toString();
                return this;
            },

            /**
             * Change handler for description
             *
             * @param {String} text             // Text
             */
            onChangeDescription(text) {
                this.unitRevision.description = text;
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Change handler for preview image
             */
            onChangePreviewImage(data) {
                this.unitRevision.preview = data.result;
                this.unitRevision.preview_thumbnail = data.result;
                this.unitRevision.previewImageForUpload = (data.files instanceof FileList && data.files.length >= 1) ? data.files[0] : null;
                this.$emit('change', this.unitRevision);
            },

            /**
             * Change handler for title
             *
             * @param {String} text             // Text
             */
            onChangeTitle(text) {
                this.unitRevision.title = text;
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Change handler for type
             *
             * @param {String} unitType      // Constant from UnitType
             */
            onChangeType(unitType) {
                unitType = UnitType.getByTypeName(unitType);
                // Show modal dialog:
                if (unitType !== null && this.unitData.type !== unitType.type)
                {
                    this.$globalEvents.emit(EventType.MODAL_CHANGE_UNIT_TYPE_SHOW, unitType);
                }
                return this;
            },

            /**
             * Change handler for policy
             *
             * @param {String} policy
             */
            onChangePolicy(policy) {
                this.$emit('change', this.unit);
                return this;
            },

            /**
             * Change handler for UnitData
             */
            onChangeUnitData() {
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Click handler for type change modal dialog apply button
             *
             * @param {UnitType} unitType
             * @param {Boolean} keepAssetLocations
             */
            onApplyChangeUnitType(unitType, keepAssetLocations) {
                // Update the actual type on the model:
                this.unitData.changeType(unitType, keepAssetLocations);
                this.$emit('change', this.unitData);
                // Set focus back on the dropdown:
                this.$nextTick(function() {
                    this.$refs.dropdownUnitType.$el.children[1].focus();
                });
                return this;
            },

            /**
             * Click handler for type change modal dialog cancel button
             *
             * @param {UnitType} unitType
             */
            onCancelChangeUnitType(unitType) {
                // Reset the dropdown value:
                this.refresh();
                // Set focus back on the dropdown:
                this.$nextTick(function() {
                    this.$refs.dropdownUnitType.$el.children[1].focus();
                });
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
