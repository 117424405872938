
export const Permission = Object.freeze(class Permission {

    // Telescope
    static TelescopeAccess                  () { return 'telescope:access'; }

    // Instance Management
    static InstanceManagementAccess         () { return 'instance_management:access'; }
    static InstanceManagementPermissions    () { return 'instance_management:permissions'; }
    static InstanceManagementFeatures       () { return 'instance_management:features'; }
    static InstanceManagementOpenApi        () { return 'instance_management:open_api'; }
    static InstanceManagementTranslations   () { return 'instance_management:translations'; }
    static InstanceManagementCommands       () { return 'instance_management:commands'; }
    static CommandsYouniumSynchronize       () { return 'instance_management_commands:younium_synchronize'; }
    static CommandsHelp                     () { return 'instance_management_commands:help'; }


    // API Tokens
    static TokensRead                       () { return 'tokens:read'; }
    static TokensCreate                     () { return 'tokens:create'; }
    static TokensDelete                     () { return 'tokens:delete'; }
    static TokensDeleteAny                  () { return 'tokens:delete_any'; }

    // Local Features:
    static LocalFeaturesRead                () { return 'local_features:read'; }
    static LocalFeaturesCreate              () { return 'local_features:create'; }
    static LocalFeaturesDelete              () { return 'local_features:delete'; }

    // Assets
    static AssetsAccess                     () { return 'assets:access'; }
    static AssetsAddToLibrary               () { return 'assets:add_to_library'; }
    static AssetsRemoveFromLibrary          () { return 'assets:remove_from_library'; }
    static AssetsCreate                     () { return 'assets:create'; }
    static AssetsDownload                   () { return 'assets:download'; }
    static AssetsRead                       () { return 'assets:read'; }
    static AssetsUpdate                     () { return 'assets:update'; }
    static AssetsDelete                     () { return 'assets:delete'; }

    static AssetsArchive                    () { return 'assets:archive'; }
    static AssetsRestoreArchived            () { return 'assets:restore_archived'; }

    // AssetPolicy
    static AssetsCreatePolicySample               () { return 'assets:create_policy_sample'; }
    static AssetsCreatePolicyStandard             () { return 'assets:create_policy_standard'; }
    static AssetsCreatePolicyUseOnly              () { return 'assets:create_policy_use_only'; }
    static AssetsCreatePolicyTemplate             () { return 'assets:create_policy_template'; }
    static AssetsUpdatePolicySample               () { return 'assets:update_policy_sample'; }
    static AssetsUpdatePolicyStandard             () { return 'assets:update_policy_standard'; }
    static AssetsUpdatePolicyUseOnly              () { return 'assets:update_policy_use_only'; }
    static AssetsUpdatePolicyTemplate             () { return 'assets:update_policy_template'; }
    static AssetsDeletePolicySample               () { return 'assets:delete_policy_sample'; }
    static AssetsDeletePolicyStandard             () { return 'assets:delete_policy_standard'; }
    static AssetsDeletePolicyUseOnly              () { return 'assets:delete_policy_use_only'; }
    static AssetsDeletePolicyTemplate             () { return 'assets:delete_policy_template'; }
    static AssetsArchivePolicySample              () { return 'assets:archive_policy_sample'; }
    static AssetsArchivePolicyStandard            () { return 'assets:archive_policy_standard'; }
    static AssetsArchivePolicyUseOnly             () { return 'assets:archive_policy_use_only'; }
    static AssetsArchivePolicyTemplate            () { return 'assets:archive_policy_template'; }
    static AssetsRestoreArchivedPolicySample      () { return 'assets:restore_archived_policy_sample'; }
    static AssetsRestoreArchivedPolicyStandard    () { return 'assets:restore_archived_policy_standard'; }
    static AssetsRestoreArchivedPolicyUseOnly     () { return 'assets:restore_archived_policy_use_only'; }
    static AssetsRestoreArchivedPolicyTemplate    () { return 'assets:restore_archived_policy_template'; }


    // Courses
    static CoursesAccess                    () { return 'courses:access'; }
    static CoursesRead                      () { return 'courses:read'; }
    static CoursesReadUnit                  () { return 'courses:read_unit'; }
    static CoursesReadUnitRevision          () { return 'courses:read_unit_revision'; }
    static CoursesCreate                    () { return 'courses:create'; }
    static CoursesDelete                    () { return 'courses:delete'; }
    static CoursesLaunchUnit                () { return 'courses:launch_unit'; }
    static CoursesUpdate                    () { return 'courses:update'; }
    static CoursesEnrollUsers               () { return 'courses:enroll_users'; }
    static CoursesEnrollUsersInOwn          () { return 'courses:enroll_users_in_own'; }
    static CoursesEnrollUsersInAny          () { return 'courses:enroll_users_in_any'; }

    // CoursePolicy
    static CoursesCreateWithPolicy          () { return 'courses:create_with_policy'; }
    static CoursesAccessPolicySample        () { return 'courses:access_policy_sample'; }
    static CoursesAccessPolicyStandard      () { return 'courses:access_policy_standard'; }

    // UnitPolicy
    static UnitsCreateWithPolicy            () { return 'units:create_with_policy'; }
    static UnitsAccessPolicySample          () { return 'units:access_policy_sample'; }
    static UnitsAccessPolicyTemplate        () { return 'units:access_policy_template'; }
    static UnitsAccessPolicyStandard        () { return 'units:access_policy_standard'; }

    // Authoring (Unit)
    static AuthoringAccess                  () { return 'authoring:access'; }

    // Training Experiences
    static ExperiencesCreate                () { return 'experiences:create'; }
    static ExperiencesExport                () { return 'experiences:export'; }

    // Units
    static UnitsAccess                      () { return 'units:access'; }
    static UnitsRead                        () { return 'units:read'; }
    static UnitsReadOwn                     () { return 'units:read_own'; }
    static UnitsReadAny                     () { return 'units:read_any'; }
    static UnitsReadAssignedForAuthoring    () { return 'units:read_assigned_for_authoring'; }
    static UnitsReadLmsLaunch               () { return 'units:read_lms_launch'; }
    static UnitsReadGlobalReleasedSamples   () { return 'units:read_global_released_samples'; }
    static UnitsReadGlobalReleasedTemplates () { return 'units:read_global_released_templates'; }
    static UnitsCreate                      () { return 'units:create'; }
    static UnitsDelete                      () { return 'units:delete'; }
    static UnitsDeleteReleased              () { return 'units:delete_released'; }
    static UnitsDuplicate                   () { return 'units:duplicate'; }
    static UnitsDuplicateToForeignTenant    () { return 'units:duplicate_to_foreign_tenant'; }
    static UnitsExport                      () { return 'units:export'; }
    static UnitsImport                      () { return 'units:import'; }
    static UnitsImportTemplate              () { return 'units:import_template'; }
    static UnitsLaunch                      () { return 'units:launch'; }
    static UnitsLaunchPreview               () { return 'units:launch_preview'; }
    static UnitsRelease                     () { return 'units:release'; }
    static UnitsRestore                     () { return 'units:restore'; }
    static UnitsUpdate                      () { return 'units:update'; }
    static UnitsAssignAuthors               () { return 'units:assign_authors'; }
    static UnitsAssignAuthorsInOwn          () { return 'units:assign_authors_in_own'; }
    static UnitsAssignAuthorsInAny          () { return 'units:assign_authors_in_any'; }

    // Units - LMS:
    static UnitsGrantAccessViaLms           () { return 'units:grant_access_via_lms'; }

    // Users
    static UsersAccess                      () { return 'users:access'; }
    static UsersAssignUserRole              () { return 'users:assign_user_role'; }
    static UsersReadOwn                     () { return 'users:read_own'; }
    static UsersRead                        () { return 'users:read'; }
    static UsersCreate                      () { return 'users:create'; }
    static UsersDelete                      () { return 'users:delete'; }
    static UsersExport                      () { return 'users:export'; }
    static UsersImport                      () { return 'users:import'; }
    static UsersUpdate                      () { return 'users:update'; }

    static UsersUpdateUserRole              () { return 'users:update_user_role'; }

    static UsersUpdateOwn                   () { return 'users:update_own'; }
    static UsersUpdateOwnPassword           () { return 'users:update_own_password'; }

    static UsersUpdatePassword              () { return 'users:update_password'; }
    static UsersUpdateAny                   () { return 'users:update_any'; }
    static UsersUpdateAnyPassword           () { return 'users:update_any_password'; }

    static UsersDeleteAny                   () { return 'users:delete_any'; }
    static UsersDeleteOwn                   () { return 'users:delete_own'; }

    // Tenants
    static TenantsAccess                    () { return 'tenants:access'; }
    static TenantsAccessCurrent             () { return 'tenants:access_current'; }
    static TenantsAssignRole                () { return 'tenants:assign_role'; }
    static TenantsRead                      () { return 'tenants:read'; }
    static TenantsReadAny                   () { return 'tenants:read_any'; }
    static TenantsCreate                    () { return 'tenants:create'; }
    static TenantsDelete                    () { return 'tenants:delete'; }
    static TenantsDeleteAny                 () { return 'tenants:delete_any'; }
    static TenantsUpdate                    () { return 'tenants:update'; }
    static TenantsUpdateAny                 () { return 'tenants:update_any'; }
    static TenantsConfigureHubspot          () { return 'tenants:configure_hubspot'; }
    static TenantsImpersonateRoleAdmin      () { return 'tenants:impersonate_role_admin'; }

    // Unit Links:
    static UnitLinksCreate                  () { return 'unit_links:create'; }
    static UnitLinksRead                    () { return 'unit_links:read'; }
    static UnitLinksDelete                  () { return 'unit_links:delete'; }
    static UnitLinksDeleteAny               () { return 'unit_links:delete_any'; }
    static UnitLinksDeleteOwn               () { return 'unit_links:delete_own'; }
    static UnitLinksUpdate                  () { return 'unit_links:update'; }
    static UnitLinksUpdateAny               () { return 'unit_links:update_any'; }
    static UnitLinksUpdateOwn               () { return 'unit_links:update_own'; }
    static UnitLinksLaunch                  () { return 'unit_links:launch'; }

    // Statistics
    static StatisticsAccess                 () { return 'statistics:access'; }

    // Services
    static ServicesCognitiveServices        () { return 'services:cognitive_services'; }
    static ServicesCognitiveServicesOpenAI  () { return 'services:cognitive_services_openai'; }

    /**
     * Split a permission string to get the ability name without the model prefix.
     *
     * @example
     * const tenantAccess = Permission.TenantsAccess(); // result: tenants:access
     * const ability = Permission.ability(Permission.TenantsAccess()); // result: access
     *
     * @param  {string} permission
     * @param  {string} separator
     * @return {string}
     */
    static ability(permission, separator = ':')
    {
        return permission.substring(permission.lastIndexOf(separator) + 1);
    }
});
