<template>
    <section
        v-if="course"
        :class="{ 'selected': selected }"
        class="course-item"
        @click="$emit('click', course)"
        @dblclick="onDoubleClick"
        @focusin="$emit('focus', course)"
    >

        <div v-if="labels.length > 0" class="wrapper course-labels">
            <StatusLabel
                v-for="labelConfig in labels"
                :key="'statuslabel' + course.uid+(labelConfig.type || labelConfig.caption)"
                :config="labelConfig"
            />
        </div>

        <a
            v-tooltip="'buttons.courses.view'"
            :href="route('courses.view', {'course': course.uid})"
            class="course-preview"
            @click.stop
        >
            <Icon name="icon_preview" />
            <img :src="course.preview_thumbnail" alt="">
        </a>

        <div class="metadata">
            <span class="unit-count">{{ unitCountHeadline }}</span>
            <span class="enrollment-status"><Icon name="icon_users_filled" />{{ enrollmentStatusHeadline }}</span>
        </div>

        <div class="content">
            <h2 class="title">
                {{ course.title }}
            </h2>
            <p class="description">
                {{ course.description }}
            </p>
        </div>

        <footer>
            <template v-if="courseHasActions">
                <div class="wrapper actions">
                    <ButtonCircular
                        v-if="courseIsEditable"
                        v-tooltip="'buttons.courses.edit'"
                        :href="route('courses.edit', {'course': course.uid})"
                        icon="icon_edit"
                    />
                    <ButtonCircular
                        v-if="courseIsDeletable"
                        v-tooltip="'buttons.courses.delete'"
                        icon="icon_delete"
                        @trigger="onClickDelete"
                    />
                </div>
                <Icon class="icon-more" name="icon_more_horizontal" />
            </template>
        </footer>

    </section>
</template>

<script lang="ts">
import Course from '@/Models/Course/Course';
import {route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import EventType from '@/Utility/EventType';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import {CoursePermissionPolicyStandard} from '@/Models/Course/CoursePermissionPolicy';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import Icon from '@/Vue/Common/Icon.vue';
import {defineComponent} from 'vue';

export default defineComponent({

    components: {
        Icon,
        StatusLabel,
        ButtonCircular
    },

    props: {
        course: {
            type: Course,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },

    emits: {
        'focus': (_: Course) => true,
        'click': (_: Course) => true,
        'dblclick': (_: Course) => true,
    },

    computed: {
        unitCountHeadline() {
            switch (this.course.numberOfUnits) {
                case 0:
                    return trans('courses.index.number_of_units_zero');
                case 1:
                    return trans('courses.index.number_of_units_one');
                default:
                    return trans('courses.index.number_of_units_many', { count: this.course.numberOfUnits });
            }
        },

        enrollmentStatusHeadline() {
            if (this.course.auto_enrollment) {
                return trans('courses.index.enrolled_users_label_all');
            } else {
                return this.course.numberOfEnrollments.toString();
            }
        },

        labels() {
            const labels: StatusLabelConfig[] = [];

            // Policy (if non-standard)
            if (this.course.policy !== CoursePermissionPolicyStandard.type) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'policy',
                        caption: this.course.policy
                    })
                );
            }

            return labels;
        },

        courseIsDeletable() {
            return this.$gate.allows(Permission.ability(Permission.CoursesDelete()), this.course);
        },

        courseIsEditable() {
            return this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), this.course);
        },

        courseHasActions() {
            return this.courseIsDeletable || this.courseIsEditable;
        },
    },

    methods: {
        route,

        onDoubleClick() {
            this.$emit('dblclick', this.course);
        },

        onClickDelete() {
            this.$globalEvents.emit(EventType.MODAL_DELETE_COURSE_SHOW, this.course);
        },

    }
});
</script>

<style lang="scss" scoped>

.course-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 320px;
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    transition: box-shadow 0.15s ease-in-out;

    &.selected,
    &:hover {
        box-shadow: var(--card-box-shadow-hover);
    }

    & > .course-labels {
        display: flex;
        position: absolute;
        padding: 10px;
        justify-content: space-between;
        width: 100%;
    }

    .course-preview {
        width: 100%;
        aspect-ratio: 16/9;
        position: relative;
        display: grid;
        justify-items: center;
        align-items: center;
        color: white;

        &:before {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            background-color: var(--color-primary-transparent);
            pointer-events: none;
            opacity: 0;
            transition: background-color 0.1s, opacity 0.1s;
        }

        &:hover:before,
        &:focus-visible:before {
            background-color: var(--color-primary-hover-transparent);
        }

        .icon, img {
            grid-area: main;
        }

        .icon {
            width: 72px;
            height: 72px;
            transition: opacity 0.3s;
            opacity: 0;
        }
    }

    &.selected .course-preview,
    &:hover .course-preview,
    .course-preview:focus-visible {
        &:before, .icon {
            opacity: 1;
        }
    }

    .metadata {
        display: flex;
        justify-content: space-between;
        padding: 21px 24px 15px 24px;
        font-family: var(--font-family-condensed-demibold);
        color: var(--color-anthracite40);
        line-height: var(--line-height-small);

        .icon {
            width: 20px;
            height: 20px;
            margin-top: -4px;
            margin-right: 4px;
        }
    }

    .content {
        flex-grow: 3;
        padding: 0 24px;

        .title {
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: var(--font-size-medium);
            line-height: 24px;
            font-family: var(--font-family-condensed-demibold);
        }

        .description {
            display: -webkit-box;
            line-height: var(--line-height-default);
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    footer {
        padding: 24px 24px 16px 24px;
        display: grid;
        min-height: 84px;

        .wrapper {
            display: flex;
            grid-row: 1 / 1;
            grid-column: 1 / 1;
            grid-gap: 2px;
            justify-content: flex-end;

            &.course-labels {
                justify-self: flex-start;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                pointer-events: none;
            }

            &.actions {
                display: none;
            }

            .btn {
                padding: 0;
                flex-basis: auto;
            }
        }

        .icon-more {
            grid-row: 1 / 1;
            grid-column: 1 / 1;
            grid-gap: 2px;
            justify-self: flex-end;
            align-self: center;
            width: 12px;
            height: 12px;
            margin: 14px;
        }
    }

    &:hover,
    &:focus,
    &:focus-within,
    &.selected {
        footer .wrapper {
            &.actions {
                display: flex;
            }
        }

        footer .icon-more {
            display: none;
        }
    }
}

</style>
