<template>
    <aside
        id="sidepanel-unitrevisions"
        :class="{ 'layout-sidepanel': true, 'open': isVisible }"
        v-shortcuts.global.stop
    >
        <div v-show="isVisible" class="sidepanel-content">
            <header>
                <Icon name="icon_doublearrow_right" class="icon-close" @click="cancelRevisionSelection" />
                {{ trans('labels.released_units') }}
            </header>

            <ListFiltersBar
                class="sidepanel-filters"
                ref="sidePanelFilters"
                :categories="filterCategories"
                :filterWords="filterWords"
                @change="applyFilters"
                @cancel="cancelRevisionSelection"
            />

            <ul class="selectable-list" v-not-focusable>

                <template v-for="(filterGroup, groupIndex) in filterSections">

                    <SidepanelSectionHeadline
                        v-if="filterGroup.headline"
                        :text="filterGroup.headline"
                    />

                    <SidepanelUnitRevisionsListItem
                        v-for="(revision, index) in filterGroup.entries"
                        :revision="revision"
                        :key="'sidepanelunitrevision_'+groupIndex+'_'+index"
                        @click="onSelectUnitRevision"
                    />
                </template>
            </ul>
        </div>
    </aside>
</template>

<script>

    // Import helpers and functions:
    import { sortArrayByProperty, trans }   from '@/Utility/Helpers';

    // Import VueJS components:
    import ListFiltersBar                   from '@/Vue/Common/ListFiltersBar.vue';

    // Import classes:
    import EventType                        from '@/Utility/EventType';
    import Search                           from '@/Utility/Search';
    import UnitRevisionFilters              from '@/Filters/UnitRevisionFilters';
    import SidepanelUnitRevisionsListItem   from '@/Vue/Sidepanel/SidepanelUnitRevisionsListItem';
    import SidepanelSectionHeadline         from '@/Vue/Sidepanel/SidepanelSectionHeadline';
    import FilterCategory                   from '@/Filters/FilterCategory';
    import MultiFilterCategory              from '@/Filters/MultiFilterCategory';
    import UnitFilters from '@/Filters/UnitFilters.js';

    export default {
        name: 'SidepanelUnitRevisions',
        components: {
            SidepanelSectionHeadline,
            SidepanelUnitRevisionsListItem,
            ListFiltersBar
        },

        props: {
            units: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        data() {
            return {
                isVisible: false,           // Visibility state
                previousFocusElement: null, // DOM element that had focus before the dialog was shown
                filterCategories: [         // List of default FilterCategory items
                    UnitFilters.EditCourseAll.setActive(),
                    UnitFilters.PolicyStandard,
                    UnitFilters.FreeLibrary,
                ],
                filterSections: [],
                filterOptions: null,        // Filter options
                filterWords: null,          // Filter words for the text input
                callback: null,             // Callback object or function
                shortcuts: new Map([
                    ['Escape', this.cancelRevisionSelection]
                ])
            }
        },
        mounted() {
            this.applyFilters();

            this.$globalEvents.on(EventType.SIDEPANEL_UNITREVISIONS_SHOW, this.onShow);
            this.$globalEvents.on(EventType.SIDEPANEL_UNITREVISIONS_HIDE, this.onHide);
            this.$globalEvents.on(EventType.SIDEPANEL_UNITREVISIONS_CANCEL, this.onHide);
        },
        beforeUnmount() {
            this.$globalEvents.removeEvent('click.global.unitrevisions-list', this.onClickGlobal);
            this.$globalEvents.off(EventType.SIDEPANEL_UNITREVISIONS_SHOW, this.onShow);
            this.$globalEvents.off(EventType.SIDEPANEL_UNITREVISIONS_HIDE, this.onHide);
            this.$globalEvents.off(EventType.SIDEPANEL_UNITREVISIONS_CANCEL, this.onHide);
        },
        methods: {

            /**
             * Apply filters
             *
             * @param {FilterCategory|String|Null} filterText
             */
            applyFilters(filterText) {
                // Store filter text:
                if ((filterText === null || typeof filterText === 'string') && filterText !== this.filterWords)
                {
                    this.filterWords = filterText;
                }

                // Show all revisions by default if there is no filter category
                if (this.filterCategories.length === 0)
                {
                    this.filterSections = [{
                        entries: this.filteredListForCategory(UnitRevisionFilters.All)
                    }];
                    return this;
                }

                // Apply filter categories:
                this.filterSections = [];
                this.filterCategories.filter(fc => fc.isActive === true).forEach(category => {

                    // For a simple FilterCategory only one group without headline will be created
                    if (category instanceof FilterCategory)
                    {
                        const filteredList = this.filteredListForCategory(category);
                        this.filterSections.push({entries: filteredList});
                        return this;
                    }

                    // For MultiFilterCategory a group for each filter with a headline gets created
                    if (category instanceof MultiFilterCategory)
                    {
                        category.sections.forEach(section => {
                            const filteredList = this.filteredListForCategory(section.category);
                            this.filterSections.push({headline: section.title, entries: filteredList});
                        });
                    }

                }, this);

                return this;
            },

            /**
             * Creates a new array with all objects that pass the category-filter.
             * They will additionally be filtered by the filterWords and sorted by their title.
             *
             * @param {FilterCategory} filterCategory
             * @returns {Array}
             */
            filteredListForCategory(filterCategory) {

                let unitList = Array.from(this.units);
                unitList = filterCategory.callback(unitList, this.filterOptions);
                let revisionsList = unitList.map(unit => unit.latestReleasedRevision);

                // Apply word filter:
                if (this.filterWords !== null)
                {
                    revisionsList = Search.filterObjects(revisionsList, ['title', 'description'], this.filterWords);
                }

                // Sort revisions by title:
                revisionsList = sortArrayByProperty(revisionsList, 'title');
                return revisionsList;
            },

            /**
             * Click handler for closing the sidepanel
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                if (this.isVisible && !(this.$el.contains(e.target) || (this.$el === e.target))) {
                    this.cancelRevisionSelection();
                }
                return this;
            },

            /**
             * Select a specific unit revision
             *
             * @param {UnitRevision} revision
             */
            onSelectUnitRevision(revision) {
                this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_SELECT, revision, this.callback);
                return this;
            },

            /**
             * Cancel unit selection
             */
            cancelRevisionSelection() {
                if (!this.isVisible) {
                    return this;
                }
                this.onHide();
                this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_CANCEL);
                return this;
            },

            /**
             * Set any custom properties that may have been send with the SIDEPANEL_UNITREVISIONS_SHOW event
             *
             * @param {Object} properties
             */
            onShow(properties = null) {
                this.$globalEvents.removeEvent('click.global.unitrevisions-list', this.onClickGlobal);  // @NOTE: Needed or the event gets triggered twice sometimes
                this.$globalEvents.addEvent('click.global.unitrevisions-list', this.onClickGlobal);
                this.previousFocusElement = document.activeElement;
                properties = (properties instanceof Object) ? properties : {};
                this.callback = properties.callback || null;
                // Clear word filter if the parameter was sent with the event:
                if (typeof properties.clearWordFilter === 'boolean' && properties.clearWordFilter === true)
                {
                    this.applyFilters(null);
                }
                // Re-apply focus to search input if the side panel is currently visible (could also be done by re-rendering the child component):
                window.setTimeout(() => {
                    this.$refs.sidePanelFilters?.setFocus();
                }, 1);
                this.isVisible = true;
                return this;
            },

            onHide() {
                this.$globalEvents.removeEvent('click.global.unitrevisions-list', this.onClickGlobal);
                this.isVisible = false;
                if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function) {
                    this.previousFocusElement.focus();
                }
                this.previousFocusElement = null;
                return this;
            }
        },
        watch: {
            units() {
                // Re-apply the filters:
                this.applyFilters();
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
