export default class EventType
{
    /**
     * EventType constants
     */

    // Header toolbar/navigation: =================================================================

    static get HEADER_NAVIGATION_BUTTON_CLICK()         { return 'header-navigation-button-click'; }

    // Inspector: =================================================================================

    static get INSPECTOR_INSPECT()                      { return 'inspector-inspect'; }
    static get INSPECTOR_ASSET_UPDATED()                { return 'inspector-asset-updated'; }
    static get INSPECTOR_DUPLICATE_SCENE_OBJECT()       { return 'inspector-duplicate-scene-object'; }
    static get INSPECTOR_REFRESH()                      { return 'inspector-refresh'; }
    static get INSPECTOR_SCENE_OBJECT_UPDATED()         { return 'inspector-scene-object-updated'; }
    static get INSPECTOR_TRIGGERS_UPDATED()             { return 'inspector-triggers-updated'; }
    static get INSPECTOR_UNIT_UPDATED()                 { return 'inspector-unit-updated'; }
    static get INSPECTOR_UNIT_SCENE_UPDATED()           { return 'inspector-unit-scene-updated'; }

    // Modal Dialogs: =============================================================================

    static get MODAL_ABOUT_SHOW()                       { return 'modal-about-show'; }
    static get MODAL_ABOUT_HIDE()                       { return 'modal-about-hide'; }

    static get MODAL_APPLY_CANCEL_SHOW()                { return 'modal-apply-cancel-show'; }
    static get MODAL_APPLY_CANCEL_HIDE()                { return 'modal-apply-cancel-hide'; }
    static get MODAL_APPLY_CANCEL_CANCEL()              { return 'modal-apply-cancel-cancel'; }
    static get MODAL_APPLY_CANCEL_APPLY()               { return 'modal-apply-cancel-apply'; }

    static get MODAL_ASSET_PREVIEW_CANCEL()             { return 'modal-asset-preview-hide'; }
    static get MODAL_ASSET_PREVIEW_HIDE()               { return 'modal-asset-preview-hide'; }
    static get MODAL_ASSET_PREVIEW_SHOW()               { return 'modal-asset-preview-show'; }

    static get MODAL_SKETCHFAB_MODEL_PREVIEW_CANCEL()   { return 'modal-sketchfab-model-preview-hide'; }
    static get MODAL_SKETCHFAB_MODEL_PREVIEW_HIDE()     { return 'modal-sketchfab-model-preview-hide'; }
    static get MODAL_SKETCHFAB_MODEL_PREVIEW_SHOW()     { return 'modal-sketchfab-model-preview-show'; }

    static get MODAL_INSTALL_APP_CANCEL()               { return 'modal-install-app-cancel'; }
    static get MODAL_INSTALL_APP_HIDE()                 { return 'modal-install-app-hide'; }
    static get MODAL_INSTALL_APP_SHOW()                 { return 'modal-install-app-show'; }

    static get MODAL_PROGRESS_SHOW()                    { return 'MODAL_PROGRESS_SHOW'; }
    static get MODAL_PROGRESS_HIDE()                    { return 'MODAL_PROGRESS_HIDE'; }

    static get MODAL_CHANGE_UNIT_TYPE_SHOW()            { return 'modal-change-unit-type-show'; }
    static get MODAL_CHANGE_UNIT_TYPE_HIDE()            { return 'modal-change-unit-type-hide'; }
    static get MODAL_CHANGE_UNIT_TYPE_CANCEL()          { return 'modal-change-unit-type-cancel'; }
    static get MODAL_CHANGE_UNIT_TYPE_APPLY()           { return 'modal-change-unit-type-apply'; }

    static get MODAL_DUPLICATE_UNIT_SHOW()              { return 'modal-duplicate-unit-show'; }
    static get MODAL_DUPLICATE_UNIT_HIDE()              { return 'modal-duplicate-unit-hide'; }
    static get MODAL_DUPLICATE_UNIT_CANCEL()            { return 'modal-duplicate-unit-cancel'; }
    static get MODAL_DUPLICATE_UNIT_APPLY()             { return 'modal-duplicate-unit-apply'; }

    static get MODAL_IMPORT_TEMPLATE_UNIT_SHOW()        { return 'modal-import-template-unit-show'; }
    static get MODAL_IMPORT_TEMPLATE_UNIT_HIDE()        { return 'modal-import-template-duplicate-unit-hide'; }
    static get MODAL_IMPORT_TEMPLATE_UNIT_CANCEL()      { return 'modal-import-template-duplicate-unit-cancel'; }
    static get MODAL_IMPORT_TEMPLATE_UNIT_APPLY()       { return 'modal-import-template-duplicate-unit-apply'; }

    static get MODAL_DUPLICATE_UNIT_SCENE_SHOW()        { return 'modal-duplicate-unit-scene-show'; }
    static get MODAL_DUPLICATE_UNIT_SCENE_HIDE()        { return 'modal-duplicate-unit-scene-hide'; }
    static get MODAL_DUPLICATE_UNIT_SCENE_CANCEL()      { return 'modal-duplicate-unit-scene-cancel'; }
    static get MODAL_DUPLICATE_UNIT_SCENE_APPLY()       { return 'modal-duplicate-unit-scene-apply'; }

    static get MODAL_NOTIFICATION_SHOW()                { return 'modal-notification-show'; }
    static get MODAL_NOTIFICATION_HIDE()                { return 'modal-notification-hide'; }

    static get MODAL_RELEASE_UNIT_SHOW()                { return 'modal-release-unit-show'; }
    static get MODAL_RELEASE_UNIT_HIDE()                { return 'modal-release-unit-hide'; }
    static get MODAL_RELEASE_UNIT_CANCEL()              { return 'modal-release-unit-cancel'; }
    static get MODAL_RELEASE_UNIT_APPLY()               { return 'modal-release-unit-apply'; }

    static get MODAL_DELETE_UNIT_SHOW()                 { return 'modal-delete-unit-show'; }
    static get MODAL_DELETE_UNIT_HIDE()                 { return 'modal-delete-unit-hide'; }
    static get MODAL_DELETE_UNIT_CANCEL()               { return 'modal-delete-unit-cancel'; }
    static get MODAL_DELETE_UNIT_APPLY()                { return 'modal-delete-unit-apply'; }

    static get MODAL_DELETE_COURSE_SHOW()               { return 'modal-delete-course-show'; }
    static get MODAL_DELETE_COURSE_HIDE()               { return 'modal-delete-course-hide'; }
    static get MODAL_DELETE_COURSE_CANCEL()             { return 'modal-delete-course-cancel'; }
    static get MODAL_DELETE_COURSE_APPLY()              { return 'modal-delete-course-apply'; }

    static get MODAL_DELETE_USER_SHOW()                 { return 'modal-delete-user-show'; }
    static get MODAL_DELETE_USER_HIDE()                 { return 'modal-delete-user-hide'; }
    static get MODAL_DELETE_USER_CANCEL()               { return 'modal-delete-user-cancel'; }
    static get MODAL_DELETE_USER_APPLY()                { return 'modal-delete-user-apply'; }

    static get MODAL_SELECT_IMPORT_USER_CSV_SHOW()      { return 'modal-select-import-user-csv-show'; }
    static get MODAL_SELECT_IMPORT_USER_CSV_HIDE()      { return 'modal-select-import-user-csv-hide'; }

    static get MODAL_IMPORT_USERS_SHOW()                { return 'modal-import-users-show'; }
    static get MODAL_IMPORT_USERS_HIDE()                { return 'modal-import-users-hide'; }

    static get MODAL_DELETE_TENANT_SHOW()               { return 'modal-delete-tenant-show'; }
    static get MODAL_DELETE_TENANT_HIDE()               { return 'modal-delete-tenant-hide'; }
    static get MODAL_DELETE_TENANT_CANCEL()             { return 'modal-delete-tenant-cancel'; }
    static get MODAL_DELETE_TENANT_APPLY()              { return 'modal-delete-tenant-apply'; }

    static get MODAL_ARCHIVE_ASSET_SHOW()               { return 'modal-archive-asset-show'; }
    static get MODAL_ARCHIVE_ASSET_HIDE()               { return 'modal-archive-asset-hide'; }
    static get MODAL_ARCHIVE_ASSET_CANCEL()             { return 'modal-archive-asset-cancel'; }
    static get MODAL_ARCHIVE_ASSET_APPLY()              { return 'modal-archive-asset-apply'; }

    static get MODAL_CREATE_ASSET_SHOW()                { return 'modal-create-asset-show'; }
    static get MODAL_CREATE_ASSET_HIDE()                { return 'modal-create-asset-hide'; }
    static get MODAL_CREATE_ASSET_CANCEL()              { return 'modal-create-asset-cancel'; }
    static get MODAL_CREATE_ASSET_SUCCESS()             { return 'modal-create-asset-success'; }

    static get MODAL_DELETE_ASSET_SHOW()                { return 'modal-delete-asset-show'; }
    static get MODAL_DELETE_ASSET_HIDE()                { return 'modal-delete-asset-hide'; }
    static get MODAL_DELETE_ASSET_CANCEL()              { return 'modal-delete-asset-cancel'; }
    static get MODAL_DELETE_ASSET_APPLY()               { return 'modal-delete-asset-apply'; }

    static get MODAL_DELETE_UNUSED_ASSETS_SHOW()        { return 'modal-delete-unused-assets-show'; }
    static get MODAL_DELETE_UNUSED_ASSETS_HIDE()        { return 'modal-delete-unused-assets-hide'; }
    static get MODAL_DELETE_UNUSED_ASSETS_CANCEL()      { return 'modal-delete-unused-assets-cancel'; }
    static get MODAL_DELETE_UNUSED_ASSETS_APPLY()       { return 'modal-delete-unused-assets-apply'; }

    static get MODAL_REMOVE_ASSET_FROM_LIBRARY_SHOW()   { return 'modal-remove-asset-from-library-show'; }
    static get MODAL_REMOVE_ASSET_FROM_LIBRARY_HIDE()   { return 'modal-remove-asset-from-library-hide'; }
    static get MODAL_REMOVE_ASSET_FROM_LIBRARY_CANCEL() { return 'modal-remove-asset-from-library-cancel'; }
    static get MODAL_REMOVE_ASSET_FROM_LIBRARY_APPLY()  { return 'modal-remove-asset-from-library-apply'; }

    static get MODAL_REMOVE_ASSET_FROM_SCENE_SHOW()     { return 'modal-remove-asset-from-scene-show'; }
    static get MODAL_REMOVE_ASSET_FROM_SCENE_HIDE()     { return 'modal-remove-asset-from-scene-hide'; }
    static get MODAL_REMOVE_ASSET_FROM_SCENE_CANCEL()   { return 'modal-remove-asset-from-scene-cancel'; }
    static get MODAL_REMOVE_ASSET_FROM_SCENE_APPLY()    { return 'modal-remove-asset-from-scene-apply'; }

    static get MODAL_REMOVE_GROUP_FROM_SCENE_SHOW()     { return 'modal-remove-group-from-scene-show'; }
    static get MODAL_REMOVE_GROUP_FROM_SCENE_HIDE()     { return 'modal-remove-group-from-scene-hide'; }
    static get MODAL_REMOVE_GROUP_FROM_SCENE_CANCEL()   { return 'modal-remove-group-from-scene-cancel'; }
    static get MODAL_REMOVE_GROUP_FROM_SCENE_APPLY()    { return 'modal-remove-group-from-scene-apply'; }

    static get MODAL_REMOVE_HOTSPOT_FROM_SCENE_SHOW()   { return 'modal-remove-hotspot-from-scene-show'; }
    static get MODAL_REMOVE_HOTSPOT_FROM_SCENE_HIDE()   { return 'modal-remove-hotspot-from-scene-hide'; }
    static get MODAL_REMOVE_HOTSPOT_FROM_SCENE_CANCEL() { return 'modal-remove-hotspot-from-scene-cancel'; }
    static get MODAL_REMOVE_HOTSPOT_FROM_SCENE_APPLY()  { return 'modal-remove-hotspot-from-scene-apply'; }

    static get MODAL_REMOVE_TEXT_FROM_SCENE_SHOW()      { return 'modal-remove-text-from-scene-show'; }
    static get MODAL_REMOVE_TEXT_FROM_SCENE_HIDE()      { return 'modal-remove-text-from-scene-hide'; }
    static get MODAL_REMOVE_TEXT_FROM_SCENE_CANCEL()    { return 'modal-remove-text-from-scene-cancel'; }
    static get MODAL_REMOVE_TEXT_FROM_SCENE_APPLY()     { return 'modal-remove-text-from-scene-apply'; }

    static get MODAL_REMOVE_UNIT_SCENE_SHOW()           { return 'modal-remove-unit-scene-show'; }
    static get MODAL_REMOVE_UNIT_SCENE_HIDE()           { return 'modal-remove-unit-scene-hide'; }
    static get MODAL_REMOVE_UNIT_SCENE_CANCEL()         { return 'modal-remove-unit-scene-cancel'; }
    static get MODAL_REMOVE_UNIT_SCENE_APPLY()          { return 'modal-remove-unit-scene-apply'; }

    static get MODAL_REMOVE_AUTHORS_FROM_UNIT_SHOW()    { return 'modal-remove-authors-from-unit-show'; }
    static get MODAL_REMOVE_AUTHORS_FROM_UNIT_HIDE()    { return 'modal-remove-authors-from-unit-hide'; }
    static get MODAL_REMOVE_AUTHORS_FROM_UNIT_CANCEL()  { return 'modal-remove-authors-from-unit-cancel'; }
    static get MODAL_REMOVE_AUTHORS_FROM_UNIT_APPLY()   { return 'modal-remove-authors-from-unit-apply'; }

    static get MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_SHOW()  { return 'modal-remove-enrollments-from-course-show'; }
    static get MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_HIDE()  { return 'modal-remove-enrollments-from-course-hide'; }
    static get MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_CANCEL(){ return 'modal-remove-enrollments-from-course-cancel'; }
    static get MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_APPLY() { return 'modal-remove-enrollments-from-course-apply'; }

    static get MODAL_REMOVE_MODULE_FROM_SCENE_SHOW()    { return 'modal-remove-module-from-scene-show'; }
    static get MODAL_REMOVE_MODULE_FROM_SCENE_HIDE()    { return 'modal-remove-module-from-scene-hide'; }
    static get MODAL_REMOVE_MODULE_FROM_SCENE_CANCEL()  { return 'modal-remove-module-from-scene-cancel'; }
    static get MODAL_REMOVE_MODULE_FROM_SCENE_APPLY()   { return 'modal-remove-module-from-scene-apply'; }

    static get MODAL_REPLACE_ASSET_SHOW()               { return 'modal-replace-asset-show'; }
    static get MODAL_REPLACE_ASSET_HIDE()               { return 'modal-replace-asset-hide'; }
    static get MODAL_REPLACE_ASSET_CANCEL()             { return 'modal-replace-asset-cancel'; }
    static get MODAL_REPLACE_ASSET_APPLY()              { return 'modal-replace-asset-apply'; }

    static get MODAL_RESTORE_ASSET_SHOW()               { return 'modal-restore-asset-show'; }
    static get MODAL_RESTORE_ASSET_HIDE()               { return 'modal-restore-asset-hide'; }
    static get MODAL_RESTORE_ASSET_CANCEL()             { return 'modal-restore-asset-cancel'; }
    static get MODAL_RESTORE_ASSET_APPLY()              { return 'modal-restore-asset-apply'; }

    static get MODAL_SAVE_ASSET_CHANGES_SHOW()          { return 'modal-save-asset-changes-show'; }
    static get MODAL_SAVE_ASSET_CHANGES_HIDE()          { return 'modal-save-asset-changes-hide'; }
    static get MODAL_SAVE_ASSET_CHANGES_CANCEL()        { return 'modal-save-asset-changes-cancel'; }
    static get MODAL_SAVE_ASSET_CHANGES_APPLY()         { return 'modal-save-asset-changes-apply'; }
    static get MODAL_SAVE_ASSET_CHANGES_DISMISS()       { return 'modal-save-asset-changes-dismiss'; }

    static get MODAL_SAVE_UNIT_CHANGES_SHOW()           { return 'modal-save-unit-changes-show'; }
    static get MODAL_SAVE_UNIT_CHANGES_HIDE()           { return 'modal-save-unit-changes-hide'; }
    static get MODAL_SAVE_UNIT_CHANGES_CANCEL()         { return 'modal-save-unit-changes-cancel'; }
    static get MODAL_SAVE_UNIT_CHANGES_APPLY()          { return 'modal-save-unit-changes-apply'; }
    static get MODAL_SAVE_UNIT_CHANGES_DISMISS()        { return 'modal-save-unit-changes-dismiss'; }

    static get MODAL_SAVE_COURSE_CHANGES_SHOW()         { return 'modal-save-course-changes-show'; }
    static get MODAL_SAVE_COURSE_CHANGES_HIDE()         { return 'modal-save-course-changes-hide'; }
    static get MODAL_SAVE_COURSE_CHANGES_CANCEL()       { return 'modal-save-course-changes-cancel'; }
    static get MODAL_SAVE_COURSE_CHANGES_APPLY()        { return 'modal-save-course-changes-apply'; }
    static get MODAL_SAVE_COURSE_CHANGES_DISMISS()      { return 'modal-save-course-changes-dismiss'; }

    static get MODAL_UNIT_CHANGED_ON_SERVER_SHOW()      { return 'modal-unit-changed-on-server-show'; }
    static get MODAL_UNIT_CHANGED_ON_SERVER_HIDE()      { return 'modal-unit-changed-on-server-hide'; }
    static get MODAL_UNIT_CHANGED_ON_SERVER_CANCEL()    { return 'modal-unit-changed-on-server-cancel'; }
    static get MODAL_UNIT_CHANGED_ON_SERVER_APPLY()     { return 'modal-unit-changed-on-server-apply'; }

    static get MODAL_UNIT_HISTORY_SHOW()                { return 'modal-unit-history-show'; }
    static get MODAL_UNIT_HISTORY_HIDE()                { return 'modal-unit-history-hide'; }
    static get MODAL_UNIT_HISTORY_CANCEL()              { return 'modal-unit-history-cancel'; }
    static get MODAL_UNIT_HISTORY_APPLY()               { return 'modal-unit-history-apply'; }

    static get MODAL_UNIT_OVERRIDE_ON_SERVER_SHOW()     { return 'modal-unit-override-on-server-show'; }
    static get MODAL_UNIT_OVERRIDE_ON_SERVER_HIDE()     { return 'modal-unit-override-on-server-hide'; }
    static get MODAL_UNIT_OVERRIDE_ON_SERVER_CANCEL()   { return 'modal-unit-override-on-server-cancel'; }
    static get MODAL_UNIT_OVERRIDE_ON_SERVER_APPLY()    { return 'modal-unit-override-on-server-apply'; }

    static get MODAL_UNIT_RESTORE_FROM_HISTORY_SHOW()   { return 'modal-unit-restore-from-history-show'; }
    static get MODAL_UNIT_RESTORE_FROM_HISTORY_HIDE()   { return 'modal-unit-restore-from-history-hide'; }
    static get MODAL_UNIT_RESTORE_FROM_HISTORY_CANCEL() { return 'modal-unit-restore-from-history-cancel'; }
    static get MODAL_UNIT_RESTORE_FROM_HISTORY_APPLY()  { return 'modal-unit-restore-from-history-apply'; }
    static get MODAL_UNIT_RESTORE_FROM_HISTORY_AS_NEW_UNIT() { return 'modal-unit-restore-from-history-as-new-unit'; }

    static get MODAL_UNIT_CREATE_FROM_HISTORY_SHOW()    { return 'modal-unit-create-from-history-show'; }
    static get MODAL_UNIT_CREATE_FROM_HISTORY_HIDE()    { return 'modal-unit-create-from-history-hide'; }
    static get MODAL_UNIT_CREATE_FROM_HISTORY_CANCEL()  { return 'modal-unit-create-from-history-cancel'; }
    static get MODAL_UNIT_CREATE_FROM_HISTORY_APPLY()   { return 'modal-unit-create-from-history-apply'; }

    static get MODAL_UNIT_UPDATE_INCOMPATIBLE_SHOW()    { return 'modal-unit-update-incompatible-show'; }
    static get MODAL_UNIT_UPDATE_INCOMPATIBLE_HIDE()    { return 'modal-unit-update-incompatible-hide'; }
    static get MODAL_UNIT_UPDATE_INCOMPATIBLE_CANCEL()  { return 'modal-unit-update-incompatible-cancel'; }
    static get MODAL_UNIT_UPDATE_INCOMPATIBLE_APPLY()   { return 'modal-unit-update-incompatible-apply'; }

    static get MODAL_REVOKE_API_TOKEN_SHOW()            { return 'modal-revoke-api-token-show'; }
    static get MODAL_REVOKE_API_TOKEN_HIDE()            { return 'modal-revoke-api-token-hide'; }
    static get MODAL_REVOKE_API_TOKEN_CANCEL()          { return 'modal-revoke-api-token-cancel'; }
    static get MODAL_REVOKE_API_TOKEN_APPLY()           { return 'modal-revoke-api-token-apply'; }

    static get MODAL_READY_PLAYER_ME_SHOW()            { return 'modal-ready-player-me-show'; }
    static get MODAL_READY_PLAYER_ME_HIDE()            { return 'modal-ready-player-me-hide'; }
    static get MODAL_READY_PLAYER_ME_CANCEL()          { return 'modal-ready-player-me-cancel'; }
    static get MODAL_READY_PLAYER_ME_APPLY()           { return 'modal-ready-player-me-apply'; }

    static get MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_SHOW()   { return 'modal-confirm-model-conversion-issues-show'; }
    static get MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_HIDE()   { return 'modal-confirm-model-conversion-issues-hide'; }
    static get MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_CANCEL() { return 'modal-confirm-model-conversion-issues-cancel'; }
    static get MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_APPLY()  { return 'modal-confirm-model-conversion-issues-apply'; }

    static get MODAL_ACCESS_TOKEN_SHOW()               { return 'modal-access-token-show'; }
    static get MODAL_ACCESS_TOKEN_HIDE()               { return 'modal-access-token-hide'; }

    static get MODAL_SELECT_TENANT_SHOW()               { return 'modal-select-tenant-show'; }
    static get MODAL_SELECT_TENANT_HIDE()               { return 'modal-select-tenant-hide'; }
    static get MODAL_SELECT_TENANT_APPLY()              { return 'modal-select-tenant-apply'; }
    static get MODAL_SELECT_TENANT_CANCEL()             { return 'modal-select-tenant-cancel'; }

    // Side panel: ================================================================================

    static get SIDEPANEL_HIDE()                         { return 'sidepanel-hide'; }

    static get SIDEPANEL_ASSETS_SELECT()                { return 'sidepanel-assets-select'; }
    static get SIDEPANEL_ASSETS_CANCEL()                { return 'sidepanel-assets-cancel'; }

    static get SIDEPANEL_SCENEOBJECTS_SHOW()            { return 'sidepanel-sceneobjects-show'; }
    static get SIDEPANEL_SCENEOBJECTS_HIDE()            { return 'sidepanel-sceneobjects-hide'; }
    static get SIDEPANEL_SCENEOBJECTS_FILTERS_SET()     { return 'sidepanel-sceneobjects-filters-set'; }
    static get SIDEPANEL_SCENEOBJECTS_SELECT()          { return 'sidepanel-sceneobjects-select'; }
    static get SIDEPANEL_SCENEOBJECTS_CANCEL()          { return 'sidepanel-sceneobjects-cancel'; }

    static get SIDEPANEL_TRIGGERS_SHOW()                { return 'sidepanel-triggers-show'; }
    static get SIDEPANEL_TRIGGERS_HIDE()                { return 'sidepanel-triggers-hide'; }
    static get SIDEPANEL_TRIGGERS_SET()                 { return 'sidepanel-triggers-set'; }
    static get SIDEPANEL_TRIGGERS_SELECT()              { return 'sidepanel-triggers-select'; }
    static get SIDEPANEL_TRIGGERS_CANCEL()              { return 'sidepanel-triggers-cancel'; }

    static get SIDEPANEL_UNITREVISIONS_SHOW()           { return 'sidepanel-unitrevisions-show'; }
    static get SIDEPANEL_UNITREVISIONS_HIDE()           { return 'sidepanel-unitrevisions-hide'; }
    static get SIDEPANEL_UNITREVISIONS_SELECT()         { return 'sidepanel-unitrevisions-select'; }
    static get SIDEPANEL_UNITREVISIONS_CANCEL()         { return 'sidepanel-unitrevisions-cancel'; }

    static get SIDEPANEL_USERS_SHOW()                   { return 'sidepanel-users-show'; }
    static get SIDEPANEL_USERS_HIDE()                   { return 'sidepanel-users-hide'; }
    static get SIDEPANEL_USERS_ASSIGN()                 { return 'sidepanel-users-assign'; }
    static get SIDEPANEL_USERS_FILTERS_SET()            { return 'sidepanel-users-filters-set'; }
    static get SIDEPANEL_USERS_SELECT()                 { return 'sidepanel-users-select'; }
    static get SIDEPANEL_USERS_CANCEL()                 { return 'sidepanel-users-cancel'; }

    // User Assignment: ===========================================================================

    static get USER_ASSIGNMENT_USERS_CHANGED()          { return 'user-assignment-users-changed'; }
    static get AUTHOR_ASSIGNMENT_AUTHORS_CHANGED()      { return 'author-assignment-authors-changed'; }

    // Window: ====================================================================================

    static get WINDOW_BEFORE_UNLOAD()                   { return 'window-before-unload'; }
    static get WINDOW_UNLOAD()                          { return 'window-unload'; }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === EventType)
        {
            throw new TypeError('Static class "EventType" cannot be instantiated directly.');
        }
    }
}
