<template>
    <Collapsible :initial-collapsed="true" class="inspector-panel panel-ai">
        <template #header>
            {{ trans('labels.ai') }}
        </template>
        <template #body>
            <div class="property property-feature-not-available">
                <FeatureNotAvailable :data-object="aiObject as AiDataObject" />
            </div>

            <div class="property">
                <Dropdown
                    :key="voiceKey"
                    :class="'no-wrap'"
                    :label="trans('authoring.ai.voice.language.label')"
                    :model="$.data"
                    :options="dropdownOptionsForLanguage"
                    property="selectedLocale"
                    @select="onSelectLocale"
                    @click.stop
                />
            </div>

            <div class="property property-voice">
                <header>{{ trans('authoring.ai.voice.label') }}</header>
                <Dropdown
                    :key="voiceKey"
                    :class="'no-wrap'"
                    :initial-value="aiObject.voice.name"
                    :label="trans('authoring.ai.voice.label')"
                    :options="dropdownOptionsForVoiceName"
                    @select="onSelectVoice"
                    @click.stop
                />

                <Dropdown
                    :key="voiceKey"
                    :class="'no-wrap text-capitalize'"
                    :disabled="dropdownOptionsForSpeakingStyle.length === 0"
                    :label="trans('authoring.ai.voice.speaking_style.label')"
                    :model="aiObject.voice"
                    :options="dropdownOptionsForSpeakingStyle"
                    :property="'speaking_style'"
                    @select="onSelectSpeakingStyle"
                    @click.stop
                />

                <TextInput
                    :key="voiceKey"
                    :class="{ disabled: isPreviewPlayerDisabled }"
                    :label="trans('authoring.ai.voice.preview.label')"
                    :maxlength="800"
                    :model="voice"
                    :placeholder="trans('authoring.ai.voice.preview.placeholder')"
                    :property="'preview'"
                    :required="true"
                    class="no-wrap preview-input"
                    type="textarea"
                />
                <div class="property property-preview-player">
                    <audio
                        ref="audio"
                        :class="{ disabled: isPreviewPlayerDisabled }"
                        :src="ttsAudioSource"
                        controls
                        controlslist="nodownload noplaybackrate"
                        @play="synthesizeIfNeeded(true, true)"
                        @contextmenu.prevent
                    />
                    <LoadingIndicator v-if="ttsService.isSynthesizing" />
                </div>

            </div>

            <div :key="knowledgeKey" class="property property-knowledge">
                <TextInput
                    :label="trans('authoring.ai.knowledge.label')"
                    :maxlength="1600"
                    :model="aiObject"
                    :placeholder="knowledgePlaceholder"
                    :required="false"
                    property="knowledge"
                    type="textarea"
                    @blur="onBlurKnowledge"
                    @change="onChangeKnowledge"
                />
            </div>
        </template>
    </Collapsible>
</template>

<script lang="ts">
import {shortId, trans} from '@/Utility/Helpers';
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import Collapsible from '@/Vue/Common/Collapsible.vue';
import type SceneObject from '@/Models/UnitData/SceneObjects/SceneObject';
import TextInput from '@/Vue/Common/TextInput.vue';
import FeatureNotAvailable from '@/Vue/Features/FeatureNotAvailable.vue';
import TextToSpeechVoice from '@/Services/CognitiveServices/TextToSpeechVoice';
import type {Dictionary} from 'lodash';
import DropdownOption from '@/Utility/DropdownOption';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import DropdownOptionGroup from '@/Utility/DropdownOptionGroup';
import TextToSpeechService from '@/Services/CognitiveServices/TextToSpeechService';
import TextToSpeechVoiceConfig from '@/Services/CognitiveServices/TextToSpeechVoiceConfig';
import type TextToSpeechResult from '@/Services/CognitiveServices/TextToSpeechResult';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import type ISceneObjectWithAi from '@/Models/UnitData/SceneObjects/ISceneObjectWithAi';
import AiDataObject from '@/Models/UnitData/SceneObjects/AiDataObject';

export default defineComponent({
    name: 'PanelAI',

    components: {
        LoadingIndicator,
        Dropdown,
        FeatureNotAvailable,
        TextInput,
        Collapsible
    },

    props: {
        sceneObject: {
            type: Object as PropType<ISceneObjectWithAi>,
            required: true
        },
        knowledgePlaceholder: {
            type: String,
            required: true,
        },
    },

    emits: {
        'change': (_: SceneObject) => true,
    },

    data() {
        return {
            voiceKey: shortId(),
            knowledgeKey: shortId(),

            selectedLocale: 'en-US',

            ttsService: new TextToSpeechService(),
            voice: {
                preview: 'The quick brown fox jumps over the lazy dog',
                ttsResult: null as TextToSpeechResult | null,
            },

            aiObject: this.sceneObject.ai,
        };
    },

    computed: {
        AiDataObject() {
            return AiDataObject;
        },
        audioElement(): HTMLAudioElement {
            return this.$refs.audio as HTMLAudioElement;
        },

        allowedLocales(): string[] {
            // please update translations at "authoring.ai.voice" when adding locales
            return ['en-GB', 'en-US', 'fr-FR', 'de-CH', 'de-DE', 'it-IT', 'es-ES'];
        },

        isPreviewPlayerDisabled(): boolean {
            return this.aiObject.voice.name === null ||
                this.aiObject.voice.speaking_style === null ||
                this.ttsService.isSynthesizing;
        },

        voicesByLocaleFiltered(): Dictionary<TextToSpeechVoice[]> {
            const filteredByLocale = Object.entries(TextToSpeechVoice.allByLocale)
                .filter(([locale, _]) => this.allowedLocales.includes(locale));

            return Object.fromEntries(filteredByLocale);
        },

        speakingStyleForSelectedVoice(): string[] {
            const speakingStylesForVoice = this.speakingStylesForVoice(this.aiObject.voice.name!);

            return speakingStylesForVoice || [];
        },

        dropdownOptionsForLanguage(): DropdownOption[] {
            return [
                ...this.allowedLocales.map((value) => {
                    return new DropdownOption({
                        caption: trans('authoring.ai.voice.language.languages.' + value),
                        value: value,
                    });
                })
            ];
        },

        dropdownOptionsForVoiceName(): DropdownOption[] {

            const groups: DropdownOptionGroup[] = [];
            const options = {
                'with_speaking_styles': this.voicesByLocaleFiltered[this.selectedLocale].filter(voice => voice.hasMultipleStyles),
                'without_speaking_styles': this.voicesByLocaleFiltered[this.selectedLocale].filter(voice => !voice.hasMultipleStyles),
            };

            for (const optionsKey in options) {
                groups.push(new DropdownOptionGroup({
                    caption: trans('authoring.ai.voice.speaking_style.option_groups.' + optionsKey),
                    isSeparator: true,
                    collapsible: false,
                    showGroupNameInCaption: false,
                    options: options[optionsKey].map((voice: TextToSpeechVoice) => {
                        const caption = voice.displayName ? voice.displayName : voice.shortName;
                        const styles = voice.hasMultipleStyles ? ' (' + voice.styleList?.length + ')' : '';
                        return new DropdownOption({
                            caption: caption + styles,
                            value: voice.shortName,
                        });
                    })
                }));
            }

            return groups;
        },

        dropdownOptionsForSpeakingStyle(): DropdownOption[] {
            const speakingStylesForVoice = this.speakingStyleForSelectedVoice;

            const options = [
                ...speakingStylesForVoice.map((value) => {
                    return new DropdownOption({
                        caption: trans('authoring.ai.voice.speaking_style.styles.' + value, {}, false, false) || value,
                        value: value,
                    });
                })
            ];

            // If there is more than one option the first option is "automatic" which should be separated from the
            // regular speaking styles.
            if (options.length > 1) {
                const separator = new DropdownOption({
                    isSeparator: true,
                });

                options.splice(1, 0, separator);
            }

            return options;
        },

        isTtsResultUpToDate() {
            return this.voice.ttsResult &&
                this.voice.ttsResult.plainTextInput === this.voice.preview &&
                this.voice.ttsResult.voiceConfig.voiceName === this.aiObject.voice.name &&
                this.voice.ttsResult.voiceConfig.style === this.aiObject.voice.speaking_style;
        },

        ttsVoice(): TextToSpeechVoiceConfig {
            return new TextToSpeechVoiceConfig(
                this.selectedLocale,
                this.aiObject.voice.name!,
                true,
                0,
                0,
                this.aiObject.voice.speaking_style
            );
        },

        ttsAudioSource() {
            if (this.isTtsResultUpToDate) {
                return window.URL.createObjectURL(this.voice.ttsResult!.blob);
            } else {
                // silent audio as fallback to enable play button in html audio element
                return 'data:audio/x-wav;base64,UklGRooWAABXQVZFZm10IBAAAAABAAEAIlYAAESsAAACABAAZGF0YWYWAAAAAA';
            }
        },
    },

    watch: {

        selectedLocale(newVal, oldVal) {
            const oldLanguage = oldVal.substring(0, 2);
            const newLanguage = newVal.substring(0, 2);

            if (oldLanguage === newLanguage) {
                return;
            }

            this.voice.preview = trans('authoring.ai.voice.preview.text.' + newLanguage);
        }

    },

    beforeMount() {
        if (this.aiObject.voice.name !== null) {
            this.selectedLocale = this.aiObject.voice.name.substring(0, 5);
        }
    },

    methods: {
        trans,

        onChangeKnowledge(text: string) {
            this.aiObject.knowledge = text;
            this.$emit('change', this.sceneObject);
        },

        onBlurKnowledge() {
            if (!this.aiObject.knowledge) {
                this.aiObject.resetToDefaultKnowledge();
                this.knowledgeKey = shortId();
                this.$emit('change', this.sceneObject);
            }
        },

        onSelectLocale(locale: string) {
            this.aiObject.resetToDefaultVoice(locale);
            this.voiceKey = shortId();
            this.$emit('change', this.sceneObject);
        },

        onSelectVoice(value: string) {
            this.aiObject.voice.name = value;

            // If the new voice supports the current speaking style keep it else reset to default
            if (!this.speakingStylesForVoice(this.aiObject.voice.name)
                ?.includes(this.aiObject.voice.speaking_style!)) {
                this.aiObject.voice.speaking_style = this.speakingStyleForSelectedVoice[0];
            }

            this.$emit('change', this.sceneObject);
            this.voiceKey = shortId();
            return this;
        },

        onSelectSpeakingStyle(value: string) {
            this.aiObject.voice.speaking_style = value;
            this.$emit('change', this.sceneObject);
            this.voiceKey = shortId();
            return this;
        },

        speakingStylesForVoice(voiceName: string) {
            return this.voicesByLocaleFiltered[this.selectedLocale]?.find(voice => voice.shortName == voiceName)?.styleList;
        },

        async synthesize(playAfterSynthesis = false) {
            this.voice.ttsResult = await this.ttsService.synthesize(this.voice.preview, this.ttsVoice);

            if (playAfterSynthesis) {
                // delay, so new source is already set on the element
                setTimeout(() => this.audioElement.play(), 100);
            }
        },

        /**
         * @param {boolean} playAfterSynthesis
         * @param {boolean} showErrorOverlay when true, errors during synthesis will be caught and an error dialog shown
         * @return {Promise<void>}
         */
        async synthesizeIfNeeded(playAfterSynthesis: boolean = false, showErrorOverlay: boolean = true): Promise<void> {
            if (this.isTtsResultUpToDate) {
                // synthesis is up-to-date
                return;
            }

            try {
                await this.synthesize(playAfterSynthesis);
            } catch (e) {
                if (showErrorOverlay) {
                    this.$root!.showErrorDialog(e);
                } else {
                    throw e;
                }
            }
        },
    }
});
</script>

<style lang="scss" scoped>
.panel-ai {
    .property.property-feature-not-available:empty {
        display: none;
    }

    .property-voice {
        & > header {
            font-family: var(--font-family-condensed-demibold);
        }
    }

    .textinput.type-textarea.disabled {
        pointer-events: none;

        :deep(textarea) {
            opacity: 0.5;
        }
    }

    .preview-input :deep(textarea) {
        min-height: 80px !important;
    }

    .property.property-preview-player {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        audio {
            width: 200px;
            border-radius: var(--btn-border-radius);
            transition: opacity 250ms ease;
        }

        audio.disabled {
            pointer-events: none;
            opacity: 0.2;
        }

        .loading-indicator {
            position: absolute;
            top: 0;
            right: 80px;
        }
    }
}
</style>
