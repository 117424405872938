<template>
    <main
        id="layout-main"
        v-shortcuts.prevent
        :data-loading="isLoading"
        :data-saving="isSaving"
    >
        <PageHeader
            :buttons="headerButtons"
            :page-title="trans('units.index.headline')"
        />

        <div id="layout-content">
            <div
                id="content"
                ref="content"
                v-not-focusable
                @scroll="onScrollList"
            >
                <div class="container list-filters-wrapper">
                    <ListFiltersBar
                        ref="listFiltersBar"
                        :categories="tabFilterCategories"
                        :filter-words="searchKeywords"
                        :filters="Object.values(filterCategories)"
                        :show-search-button="true"
                        :sort-descending="sortDescending"
                        :sorting-filters="sortingFilters"
                        @change="applyFilters"
                        @reset="onResetFilters"
                        @trigger-search="onTriggerSearch"
                    >
                        <template #buttons>
                            <ButtonPrimary
                                v-if="canCreateNewUnit"
                                v-tooltip="'buttons.units.create'"
                                :href="route('units.createForm')"
                                caption="units.index.btn_create"
                                class="btn-add-new"
                                icon="icon_add"
                            />
                        </template>
                    </ListFiltersBar>
                </div>

                <!-- List of units -->
                <div class="container unit-list">

                    <UnitListItem
                        v-for="unit in unitsToRender"
                        :key="'unit_' + unit.uid + unit.latest_revision_uid"
                        v-focusable
                        :selected="selectedUnit?.uid === unit.uid"
                        :unit="unit"
                        :show-release-label="true"
                        @click="onClickUnit"
                        @dblclick="onClickUnitEdit"
                        @focus="onClickUnit"
                        @click-edit="onClickUnitEdit"
                        @click-duplicate="onClickUnitDuplicate"
                        @click-import-template="onClickUnitImportTemplate"
                        @click-delete="onClickUnitDelete"
                    />

                    <template v-if="!isLoading && unitsToRender.length === 0">
                        <NoItemsFound v-if="searchKeywords" />
                        <NoItemsAvailable v-else />
                    </template>

                </div>

                <!-- Pagination -->
                <Pagination
                    v-if="units.length > 0"
                    :current-page="pagingMetadata.current_page"
                    :number-of-pages="pagingMetadata.last_page"
                    @click="onPageClicked"
                />

            </div>

            <aside id="layout-inspector" :class="{ 'open': selectedUnit }">
                <SidepanelViewUnit
                    v-if="selectedUnit"
                    :key="'panel-unit-'+selectedUnit.uid"
                    :show-author-assignment="true"
                    :unit="selectedUnit"
                />
            </aside>

            <aside :class="{ 'layout-sidepanel': true, 'open': showSidePanel }">
                <SidepanelUsers
                    :is-visible="showUsers"
                    :users="unassignedAuthors"
                    headline-label="labels.authors"
                />
            </aside>

            <!-- Modal dialog for deleting a unit -->
            <ModalApplyCancel
                :apply-text="trans('modals.delete_unit.apply')"
                :cancel-text="trans('modals.delete_unit.cancel')"
                :description="descriptionTextDelete"
                :title="trans('modals.delete_unit.title')"
                event-type="MODAL_DELETE_UNIT"
            />

            <!-- Model dialog for duplicating a unit -->
            <ModalDuplicateUnit />

            <!-- Model dialog for importing a unit as template -->
            <ModalImportTemplateUnit />

            <!-- Modal dialog for removing all assigned users -->
            <ModalApplyCancel
                :description="trans('modals.remove_authors_from_unit.description')"
                :title="trans('modals.remove_authors_from_unit.title')"
                event-type="MODAL_REMOVE_AUTHORS_FROM_UNIT"
            />

            <ModalProgress />
            <ModalNotification />
        </div>
    </main>
</template>

<script lang="ts">

import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import ModalDuplicateUnit from '@/Vue/Modals/ModalDuplicateUnit.vue';
import UnitListItem from '@/Vue/Units/UnitListItem.vue';
import SidepanelUsers from '@/Vue/Sidepanel/SidepanelUsers.vue';
import SidepanelViewUnit from '@/Vue/Sidepanel/SidepanelViewUnit.vue';
import ListFiltersBar from '@/Vue/Common/ListFiltersBar.vue';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import NoItemsFound from '@/Vue/Search/NoItemsFound.vue';
import ModalImportTemplateUnit from '@/Vue/Modals/ModalImportTemplateUnit.vue';
import AuthorizationError from '@/Errors/AuthorizationError';
import EventType from '@/Utility/EventType';
import {permission, route, sortArrayByProperty, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import Unit from '@/Models/Unit/Unit';
import MultiFilterCategory from '@/Filters/MultiFilterCategory';
import SortingFilters from '@/Filters/SortingFilters';
import FilterSection from '@/Filters/FilterSection';
import UnitType from '@/Models/UnitData/UnitType';
import UnitFilters from '@/Filters/UnitFilters';
import {UnitPermissionPolicySample} from '@/Models/Unit/UnitPermissionPolicy';
import type {ShallowRef} from 'vue';
import {defineComponent, inject, shallowRef} from 'vue';
import {unitServiceKey, userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import PageHeaderButton from '@/Utility/PageHeaderButton';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import Pagination from '@/Vue/Common/Pagination.vue';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import type FilterCategory from '@/Filters/FilterCategory';
import type PagingMetadata from '@/Models/PagingMetadata';
import type UnitPage from '@/Models/Unit/UnitPage';
import type User from '@/Models/User/User';

export default defineComponent({
    components: {
        PageHeader,
        Pagination,
        ButtonPrimary,
        ModalProgress,
        ModalImportTemplateUnit,
        NoItemsFound,
        NoItemsAvailable,
        ListFiltersBar,
        SidepanelUsers,
        SidepanelViewUnit,
        ModalApplyCancel,
        ModalDuplicateUnit,
        ModalNotification,
        UnitListItem,
    },

    data() {
        return {
            /**
             * The current list of units from the service
             */
            units: shallowRef([]) as ShallowRef<Unit[]>,

            unitService: inject(unitServiceKey)!,
            userService: inject(userServiceKey)!,

            /**
             * Whether the users are visible in the side panel
             */
            showUsers: false,

            selectedUnit: null as ShallowRef<Unit> | null,

            /**
             * Content scroll position for restoring after save
             */
            scrollPos: {
                x: 0,
                y: 0
            },

            /**
             * Whether the side panel is visible
             */
            showSidePanel: false,

            /**
             * List of assigned authors for the selected unit
             */
            assignedAuthors: [] as User[],

            /**
             * List of unassigned authors for the selected unit
             */
            unassignedAuthors: [] as User[],

            /**
             * Shortcut mapping to methods
             */
            shortcuts: new Map([
                ['Save.global.prevent', null],              // Prevent browser saving
                ['Backspace.global.prevent', null],         // Prevent going back in browser history
                ['Enter', this.onShortcutEnter],            // Open unit
                ['Delete', this.onShortcutDelete],          // Delete unit
                ['Duplicate', this.onShortcutDuplicateOrImport], // Duplicate/import unit
            ]),

            /**
             * Helpers for showing the unit title in generic modal dialogs
             */
            descriptionTextDelete: trans('modals.delete_unit.description'),

            /**
             * How many items to display initially
             */
            renderItemsLoaded: 20,

            /**
             * How many items to add at once when scrolling
             */
            renderItemsPerPage: 20,

            /**
             * Filter categories for navigation tabs
             */
            tabFilterCategories: [
                UnitFilters.PolicyStandard.setActive(true, true),
                UnitFilters.FreeLibrary,
            ] as FilterCategory[],

            /**
             * Filter categories
             */
            filterCategories: {
                type: new MultiFilterCategory(
                    trans('labels.type'),
                    this.filterSectionsByType(),
                    null
                ).setActive(true, true),

                status: new MultiFilterCategory(
                    trans('labels.status'),
                    this.filterSectionsByStatus(),
                    null
                ).setActive(true, true)
            },

            /**
             * Filters for sorting
             */
            sortingFilters: [
                SortingFilters.Alphabetical,
                SortingFilters.CreatedAt,
                SortingFilters.UpdatedAt.setActive(true, true),
            ],

            /**
             * Sorting order
             */
            sortDescending: true,

            /**
             * Search keywords from the search input
             */
            searchKeywords: null as string | null,

            events: new Map([
                [EventType.MODAL_DUPLICATE_UNIT_APPLY, this.onApplyDuplicateUnit],
                [EventType.MODAL_IMPORT_TEMPLATE_UNIT_APPLY, this.onApplyImportTemplateUnit],
                [EventType.MODAL_DELETE_UNIT_SHOW, this.onShowDeleteUnit],
                [EventType.MODAL_DELETE_UNIT_APPLY, this.onApplyDeleteUnit],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
                [EventType.AUTHOR_ASSIGNMENT_AUTHORS_CHANGED, this.onChangeAuthorAssignment],
                [EventType.SIDEPANEL_USERS_ASSIGN, this.onAssignAuthorsToUnit],
                [EventType.SIDEPANEL_USERS_SHOW, this.onShowUsersSidePanel],
                [EventType.SIDEPANEL_USERS_HIDE, this.onHideUsersSidePanel],
                [EventType.SIDEPANEL_USERS_CANCEL, this.onHideUsersSidePanel],
            ]),
        };
    },

    computed: {

        listFiltersBar() {
            return this.$refs.listFiltersBar as InstanceType<typeof ListFiltersBar>;
        },

        contentElement() {
            return this.$refs.content as HTMLElement;
        },

        canAccessUsers() {
            return permission(Permission.UsersRead());
        },

        canCreateNewUnit() {
            return permission(Permission.UnitsCreate());
        },

        canImportUnits() {
            return permission(Permission.UnitsImport());
        },

        canExportUnits() {
            return permission(Permission.UnitsExport());
        },

        /**
         * List of button configurations for the page header
         */
        headerButtons() {
            const buttons: { [key: string]: PageHeaderButton } = {};

            if (this.canImportUnits) {
                buttons.importUnits = new PageHeaderButton({
                    disabled: this.isLoading || this.isSaving,
                    visible: this.canImportUnits,
                    caption: trans('labels.import'),
                    icon: 'icon_import',
                    href: route('units.imports.index'),
                });
            }

            if (this.canExportUnits) {
                buttons.exportUnits = new PageHeaderButton({
                    disabled: this.isLoading || this.isSaving,
                    visible: this.canExportUnits,
                    caption: trans('labels.export'),
                    icon: 'icon_export',
                    href: route('units.showUnitsForExport'),
                });
            }

            return buttons;
        },

        /**
         * @return the current metadata about the page from the service
         */
        pagingMetadata(): PagingMetadata {
            return this.unitService.unitPage.pagingMetadata;
        },

        isLoading(): boolean {
            if (this.unitService.isLoading || this.userService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving(): boolean {
            if (this.unitService.isSaving || this.userService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        unitsToRender(): Unit[] {
            return this.units.slice(0, this.renderItemsLoaded);
        }
    },

    watch: {
        selectedUnit() {
            // Refresh the selected unit in the UnitPage to update the UI
            const index = this.units.findIndex(u => u.uid === this.selectedUnit?.uid);
            if (index >= 0) {
                this.units.splice(index, 1, this.selectedUnit!);
            }
        },
    },

    mounted() {
        // Fetch users:
        if (this.canAccessUsers) {
            this.userService.fetchUsers().then(this.onSuccessFetchUsers).catch(this.onErrorApi);
        }

        // Add global events:
        this.$globalEvents.addEvent('click.global.unit-list', this.onClickGlobal);
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.$globalEvents.removeEvent('click.global.unit-list', this.onClickGlobal);
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },
    methods: {
        trans,
        route,

        canDeleteUnit(unit: Unit) {
            return this.$gate.allows(Permission.ability(Permission.UnitsDelete()), unit);
        },

        canDuplicateUnit(unit: Unit) {
            return this.$gate.allows(Permission.ability(Permission.UnitsDuplicate()), unit);
        },

        canImportUnitAsTemplate(unit: Unit) {
            return this.$gate.allows(Permission.ability(Permission.UnitsImportTemplate()), unit);
        },

        canUpdateUnit(unit: Unit) {
            return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), unit);
        },

        /**
         * Fetch units for the current user from API
         */
        fetchUnitsPage(page: number = 1) {
            const filters = Object.fromEntries(
                Object.entries(this.filterCategories).map(
                    ([k, v]) => [k, v.activeSections.map(s => s.category.paramName)]
                )
            );
            filters.policy = this.tabFilterCategories.filter(f => f.isActive).map(f => f.paramName).flat();
            const orderBy = this.sortingFilters.find(f => f.active)?.paramName || null;
            this.unitService
                .fetchUnits(page, null, filters, this.searchKeywords, orderBy, this.sortDescending)
                .then(this.onSuccessFetchUnits)
                .catch(this.onErrorApi);
        },

        /**
         * Success handler for loading units
         */
        onSuccessFetchUnits(unitPage: UnitPage) {
            // Reset scrolling list
            this.renderItemsLoaded = this.renderItemsPerPage;

            // Scroll to top:
            this.contentElement.scrollTo(0, 0);

            this.units = unitPage.unitList;

            if (this.selectedUnit !== null && this.unitsToRender.some(u => u.uid === this.selectedUnit!.uid)) {
                // Reselect the current unit (if one is selected, and it is on the current page)
                this.selectUnit(this.selectedUnit);
            } else {
                // Deselect any unit
                this.selectedUnit = null;
            }
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },

        /**
         * Called when the user triggered a page change.
         */
        onPageClicked(page: number) {
            this.fetchUnitsPage(page);
        },

        onClickUnit(unit: Unit) {
            this.selectUnit(unit);
        },

        onClickUnitDelete(unit: Unit) {
            if (this.canDeleteUnit(unit)) {
                this.$globalEvents.emit(EventType.MODAL_DELETE_UNIT_SHOW, unit);
            }
        },

        onClickUnitDuplicate(unit: Unit) {
            if (this.canDuplicateUnit(unit)) {
                this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_SHOW, unit);
            }
        },

        /**
         * Click event handler for importing template units
         * @param {Unit} unit
         */
        onClickUnitImportTemplate(unit: Unit) {
            if (this.canImportUnitAsTemplate(unit)) {
                this.$globalEvents.emit(EventType.MODAL_IMPORT_TEMPLATE_UNIT_SHOW, unit);
            }
        },

        /**
         * Opens the given unit for editing (if allowed)
         */
        onClickUnitEdit(unit: Unit) {
            if (this.canUpdateUnit(unit)) {
                window.location.href = route('units.edit', { 'unit': unit.uid });
            }
        },

        /**
         * Select a specific unit for editing in the side panel
         */
        selectUnit(unit: Unit) {
            // Only select the unit if it's not already selected:
            if (this.selectedUnit === null || this.selectedUnit.uid !== unit.uid) {
                this.selectedUnit = new Unit(unit);    // @NOTE: Creating a new instance, so we can later revert any changes
                this.setAuthorAssignments();
                this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.selectedUnit);
            }
        },

        /**
         * Update the users side panel
         */
        setAuthorAssignments() {
            if (this.selectedUnit !== null) {
                this.assignedAuthors = this.userService.getAssignedAuthorsForUnit(this.selectedUnit);
                this.unassignedAuthors = this.userService.getUnassignedAuthorsForUnit(this.selectedUnit);
            } else {
                this.assignedAuthors = [];
                this.unassignedAuthors = [];
            }
        },

        /**
         * Assign authors to selected unit
         */
        onAssignAuthorsToUnit(_: User[]) {
            this.onHideUsersSidePanel();
            // authors will be added in {PanelAuthorAssignment} component
        },

        /**
         * Success handler for loading users
         */
        onSuccessFetchUsers(_: User[]) {
            // Update the author assignments:
            this.setAuthorAssignments();
        },

        onScrollList(e: Event) {
            // Maximum number of items showing already
            if (this.renderItemsLoaded >= this.units.length) {
                return this;
            }

            const list = e.target as HTMLElement;
            if (list.scrollTop >= (list.scrollHeight - list.offsetHeight - (list.offsetHeight * 0.85))) {
                this.renderItemsLoaded += this.renderItemsPerPage;
            }
        },

        /**
         * Show the users in the side panel
         */
        onShowUsersSidePanel() {
            this.showUsers = true;
            this.showSidePanel = true;
            return this;
        },

        /**
         * Hide the users side panel
         */
        onHideUsersSidePanel() {
            if (this.showUsers) {
                this.showUsers = false;
                this.showSidePanel = false;
            }
        },

        /**
         * User assignment on the currently selected training has changed.
         */
        onChangeAuthorAssignment() {
            // Update list of assigned users:
            this.setAuthorAssignments();
        },

        /**
         * Apply handler for duplicating a unit
         */
        onApplyDuplicateUnit(unit: Unit, newTitle: string, keepAssignedAuthors: boolean, targetTenantUid: string) {
            this.unitService
                .duplicateUnit(unit, newTitle, keepAssignedAuthors, targetTenantUid)
                .then(this.onDuplicateUnitSuccess)
                .catch(this.onErrorApi);
        },

        /**
         * Apply handler for importing a unit as template
         */
        onApplyImportTemplateUnit(unit: Unit, newTitle: string) {
            this.unitService
                .importUnitAsTemplate(unit, newTitle)
                .then(this.onImportTemplateUnitSuccess)
                .catch(this.onErrorApi);
        },

        onDuplicateUnitSuccess(_: Unit) {
            if (this.selectedUnit?.policy === UnitPermissionPolicySample.type) {
                this.$toast.success(trans('units.import.from_template_success'));
            }
            // Load the first page since the newly duplicated unit will be the latest
            this.fetchUnitsPage(1);
        },

        onImportTemplateUnitSuccess(_: Unit) {
            this.$toast.success(trans('units.import.from_template_success'));
            // Load the first page since the newly duplicated unit will be the latest
            this.fetchUnitsPage(1);
        },

        /**
         * Show handler for deleting a unit
         */
        onShowDeleteUnit(unit: Unit) {
            this.descriptionTextDelete = trans('modals.delete_unit.description', {
                title: unit.latestRevision?.title ?? ''
            });
        },

        /**
         * Apply handler for deleting a unit
         */
        onApplyDeleteUnit(unit: Unit) {
            this.unitService
                .deleteUnit(unit)
                .then(this.onDeleteUnitSuccess)
                .catch(this.onErrorApi);
        },

        /**
         * Success handler for deleting the selected unit
         */
        onDeleteUnitSuccess(_: Unit) {
            // Refresh the unit list
            const pageToLoad = this.units.length > 1 // Are there more units on the current page?
                ? this.pagingMetadata.current_page // Yes -> Load updated units for the current page
                : this.pagingMetadata.current_page > 0 // No -> Are there still more pages?
                    ? this.pagingMetadata.current_page - 1 // Yes -> Load previous page
                    : 1; // No -> Load first page
            this.fetchUnitsPage(pageToLoad);
        },

        /**
         * Click handler for global events
         */
        onClickGlobal(e: MouseEvent) {
            const target = e.target as HTMLElement;

            // Close users side panel:
            if (
                this.showUsers &&
                !target.matches('.btn.assign-users')
                && !document.getElementById('sidepanel-users')?.contains(target)
            ) {
                this.onHideUsersSidePanel();
            }
        },

        /**
         * Shortcut handler for deleting a unit
         */
        onShortcutDelete(_: CustomEvent) {
            if (
                this.selectedUnit !== null
                && this.canDeleteUnit(this.selectedUnit)
                && document.getElementById('content')?.contains(document.activeElement)
            ) {
                this.$globalEvents.emit(EventType.MODAL_DELETE_UNIT_SHOW, this.selectedUnit);
            }
        },

        /**
         * Shortcut handler for duplicating/importing a unit.
         */
        onShortcutDuplicateOrImport() {
            if (this.selectedUnit !== null && document.getElementById('content')?.contains(document.activeElement)) {
                if (this.canDuplicateUnit(this.selectedUnit)) {
                    this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_SHOW, this.selectedUnit);
                } else if (this.canImportUnitAsTemplate(this.selectedUnit)) {
                    this.$globalEvents.emit(EventType.MODAL_IMPORT_TEMPLATE_UNIT_SHOW, this.selectedUnit);
                }
            }
        },

        /**
         * Shortcut handler for opening a unit
         */
        onShortcutEnter(_: CustomEvent) {
            // Only open the unit if the focus is on the list:
            if (
                this.selectedUnit !== null
                && this.canUpdateUnit(this.selectedUnit)
                && document.activeElement?.matches('.unit-list-item')
            ) {
                this.onClickUnitEdit(this.selectedUnit);
            }
        },

        onResetFilters() {
            this.searchKeywords = null;
            this.sortDescending = true;
            this.listFiltersBar.setFocus();
            this.fetchUnitsPage(1);
        },

        filterSectionsByType() {
            return sortArrayByProperty(
                UnitType.all.map(
                    t => new FilterSection(t.title, t.filterCategory.setActive(true, true))
                ),
                'title'
            );
        },

        filterSectionsByStatus() {
            return [
                UnitFilters.Draft,
                UnitFilters.Released,
                UnitFilters.HasUnreleasedChanges,
            ].map(f => new FilterSection(f.title, f.setActive(true, true)));
        },

        /**
         * Apply filters
         */
        applyFilters(filterObj: FilterCategory | string | object | null | undefined = undefined) {
            // Store filter keywords text (no search trigger needed):
            if (filterObj === null || typeof filterObj === 'string') {
                this.searchKeywords = filterObj;
            }

            // Order by ascending/descending:
            if (
                filterObj &&
                typeof filterObj === 'object' && Object.prototype.hasOwnProperty.call(filterObj, 'descending')
            ) {
                this.sortDescending = (filterObj as any).descending ?? this.sortDescending;
            }

            // Trigger the search:
            if (filterObj !== null && typeof filterObj === 'object') {
                this.fetchUnitsPage(1);
            }
        },

        onTriggerSearch(_: KeyboardEvent, keywords: string) {
            this.searchKeywords = keywords;
            this.fetchUnitsPage(1);
        },

        /**
         * Before unload handler
         */
        onBeforeUnload(_: BeforeUnloadEvent) {
            this.unitService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    }
});
</script>

<style lang="scss" scoped>

</style>
