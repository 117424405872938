<template>
    <section
        v-if="revisionToDisplay"
        :class="classNames"
        @click="$emit('click', unit)"
        @dblclick="$emit('dblclick', unit)"
        @focus="$emit('focus', unit)"
    >

        <img
            :alt="revisionToDisplay.title"
            :src="revisionToDisplay.preview_thumbnail"
            class="unit-preview"
        >

        <div class="unit-content">
            <h2 class="unit-title">
                {{ revisionToDisplay.title }}
                <time :datetime="moment(unit.updated_at).format()" class="unit-author">
                    {{ dateAndAuthor }}
                </time>
            </h2>
            <p class="unit-description">
                {{ revisionToDisplay.description }}
            </p>
        </div>

        <footer>
            <div class="unit-labels">
                <StatusLabel
                    v-for="labelConfig in labels"
                    :key="'statuslabel'+unit.uid+(labelConfig.type || labelConfig.caption)"
                    :config="labelConfig"
                />
            </div>

            <template v-if="unitHasActions">
                <div class="unit-actions">

                    <!-- Btn: Edit -->
                    <ButtonCircular
                        v-if="unitIsEditable"
                        v-tooltip="'buttons.units.edit'"
                        :href="route('units.edit', {'unit': unit.uid})"
                        icon="icon_edit"
                        @trigger="onEditClick"
                    />
                    <ButtonCircular
                        v-else
                        :disabled="true"
                        v-tooltip="'buttons.units.edit_off'"
                        :href="route('units.edit', {'unit': unit.uid})"
                        icon="icon_edit_off"
                    />

                    <!-- Btn: Duplicate -->
                    <ButtonCircular
                        v-if="unitIsDuplicatable"
                        v-tooltip="duplicateUnitTooltip"
                        icon="icon_duplicate"
                        @trigger="$emit('click-duplicate', unit)"
                    />

                    <!-- Btn: Import template -->
                    <ButtonCircular
                        v-if="unitIsImportableAsTemplate"
                        v-tooltip="'buttons.units.import_into_library'"
                        icon="icon_duplicate"
                        @trigger="$emit('click-import-template', unit)"
                    />

                    <!-- Btn: Delete -->
                    <ButtonCircular
                        v-if="unitIsDeletable"
                        v-tooltip="'buttons.units.delete'"
                        icon="icon_delete"
                        @trigger="$emit('click-delete', unit)"
                    />
                </div>
                <Icon class="icon-more" name="icon_more_horizontal" />
            </template>
        </footer>

    </section>
</template>

<script lang="ts">
import moment from 'moment';
import Unit from '@/Models/Unit/Unit';
import {Permission} from '@/Models/User/Permission';
import {UnitPermissionPolicySample, UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import {route, trans} from '@/Utility/Helpers';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import Icon from '@/Vue/Common/Icon.vue';
import {defineComponent} from 'vue';
import type UnitRevision from '@/Models/Unit/UnitRevision';

export default defineComponent({
    components: {
        Icon,
        ButtonCircular,
        StatusLabel
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        showPolicyLabel: {
            type: Boolean,
            default: false,
        },
        showReleaseLabel: {
            type: Boolean,
            default: false,
        }
    },

    emits: {
        'click': (_: Unit) => true,
        'dblclick': (_: Unit) => true,
        'focus': (_: Unit) => true,
        'click-delete': (_: Unit) => true,
        'click-duplicate': (_: Unit) => true,
        'click-import-template': (_: Unit) => true,
        'click-edit': (_: Unit) => true,
    },

    computed: {

        classNames() {
            return [
                'unit-list-item',
                this.selected ? 'selected' : '',
                this.unit.is_new ? 'is-new' : '',
            ];
        },

        labels(): StatusLabelConfig[] {
            const labels: StatusLabelConfig[] = [];

            // Policy (if non-standard)
            if (this.showPolicyLabel && this.unit.policy !== UnitPermissionPolicyStandard.type) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'policy',
                        caption: this.unit.policy
                    })
                );
            }

            // Released or draft
            if (this.showReleaseLabel && this.unit.isReleased) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'status',
                        caption: 'released'
                    })
                );
            } else if (this.showReleaseLabel && this.unit.isDraft) {
                labels.push(
                    new StatusLabelConfig({
                        caption: 'draft'
                    })
                );
            }

            // Unreleased changes
            if (this.showReleaseLabel && this.unit.hasUnreleasedChanges) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'notification',
                        caption: 'unreleased_changes'
                    })
                );
            }

            // Incompatible
            if (!this.revisionToDisplay.isCompatible) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'warning',
                        caption: 'incompatible_unit_open',
                        tooltip: 'incompatible_unit_open_tooltip'
                    })
                );
            }

            return labels;
        },

        revisionToDisplay(): UnitRevision {
            // Display latest released revision; or latest revision if unit can be updated
            const revisionToDisplay = this.unitIsEditable ?
                this.unit.latestRevision :
                this.unit.latestReleasedRevision;

            if (revisionToDisplay === null) {
                throw Error(`No latest (released) revision found on unit ${this.unit.uid}.`);
            }

            return revisionToDisplay;
        },

        dateAndAuthor(): string {
            const revision = this.revisionToDisplay;
            const authorName = revision.owner ? revision.owner.fullName : trans('labels.unknown_author');
            return `${moment(this.unit.updated_at).format(trans('courses.unit_list.item_date_format'))} ${trans(
                'labels.by')} ${authorName}`;
        },

        duplicateUnitTooltip(): string {
            return this.unit.policy === UnitPermissionPolicySample.type
                ? 'buttons.units.import_into_library'
                : 'buttons.units.duplicate';
        },

        unitHasActions(): boolean {
            return this.unitIsEditable
                || this.unitIsDeletable
                || this.unitIsDuplicatable
                || this.unitIsImportableAsTemplate;
        },

        unitIsDeletable(): boolean {
            return this.$gate.allows(Permission.ability(Permission.UnitsDelete()), this.unit);
        },

        unitIsDuplicatable(): boolean {
            return this.$gate.allows(Permission.ability(Permission.UnitsDuplicate()), this.unit);
        },

        unitIsImportableAsTemplate(): boolean {
            return this.$gate.allows(Permission.ability(Permission.UnitsImportTemplate()), this.unit);
        },

        unitIsEditable(): boolean {
            return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.unit);
        },
    },

    methods: {
        route,

        onEditClick(e: MouseEvent) {
            e.preventDefault();
            this.$emit('click-edit', this.unit);
        }

    }
});
</script>

<style lang="scss" scoped>

</style>
