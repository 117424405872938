<template>
    <div id="inspector" v-not-focusable class="inspector-content">
        <header class="inspector-header">
            <Icon class="inspector-panel-header-icon" name="icon_courses" />
            {{ trans('labels.inspector.course_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div :key="'InspectorPanels' + renderTimestamp" v-not-focusable class="panels">
            <div v-if="course" :key="'PanelEditCourse'+course.uid" class="inspector-panel panel-edit-course">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    :editable="true"
                    :src="previewUrl"
                    @change="onChangePreviewImage"
                />

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :error-msg="trans('errors.course.title')"
                        :maxlength="50"
                        :model="course"
                        :placeholder="trans('courses.create.course_title_placeholder')"
                        :required="true"
                        property="title"
                        @change="onChangeProperty"
                    />
                </div>

                <!-- Policy -->
                <div class="property property-type">
                    <Dropdown
                        :key="'dropdownCoursePolicy'+renderTimestamp"
                        :disabled="!isPolicyEditableByUser"
                        :label="trans('labels.policy')"
                        :model="course"
                        :options="getOptionsForPolicy"
                        class="no-wrap"
                        property="policy"
                        @select="onChangeProperty"
                    />
                </div>

                <!-- Description -->
                <div class="property property-description">
                    <TextInput
                        :label="trans('labels.description')"
                        :maxlength="600"
                        :model="course"
                        :placeholder="trans('courses.create.course_description_placeholder')"
                        :required="false"
                        property="description"
                        type="textarea"
                        @change="onChangeProperty"
                    />
                </div>

                <div class="property property-users">
                    <p class="info-changes-visible-immediately">
                        {{ trans('courses.edit.info_changes_visible_immediately') }}
                    </p>
                </div>

            </div>

        </div>
    </div>
</template>

<script lang="ts">
import InspectorItemPreviewImage from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
import Course from '@/Models/Course/Course';
import CoursePermissionPolicy from '@/Models/Course/CoursePermissionPolicy';
import DropdownOption from '@/Utility/DropdownOption';
import {permission, sortArrayByProperty, trans} from '@/Utility/Helpers';
import Icon from '@/Vue/Common/Icon.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import {defineComponent} from 'vue';

export default defineComponent({

    components: {
        Dropdown,
        TextInput,
        Icon,
        InspectorItemPreviewImage
    },

    props: {
        course: {
            type: Course,
            required: true,
        },
    },

    emits: {
        change: (_: Course) => true
    },

    data() {
        return {
            /**
             * Helper for forcing re-rendering
             */
            renderTimestamp: null as string | null,
        };
    },

    computed: {

        /**
         * Check if the user has the necessary permissions to edit the training policy.
         */
        isPolicyEditableByUser(): boolean {
            return this.getOptionsForPolicy.some(option =>
                (
                    this.course.policy === option.value
                    && option.disabled === false
                )
            );
        },

        /**
         * Options for Policy dropdown
         */
        getOptionsForPolicy(): DropdownOption[] {
            const options: DropdownOption[] = [];

            CoursePermissionPolicy.getPermissionPolicyMapping().forEach(([policyPermission, policy]) => {
                const isAllowedInTenant = !policy.hasToExistInsideAssetDefaultTenant ||
                    window.currentUser?.tenant?.is_default_asset_tenant;

                const isDisabled = !permission(policyPermission) || !isAllowedInTenant;

                options[options.length] = new DropdownOption({
                    caption: trans('unit_policies.' + policy.type),
                    value: policy.type,
                    disabled: isDisabled,
                });
            });

            return sortArrayByProperty(options, 'caption', false);
        },

        previewUrl() {
            if (this.course.preview.startsWith('data:')) {
                // do not append anything to user selected images
                return this.course.preview;
            }

            // Append last updated millis of course to avoid caching a changed course image with same url.
            // This construct can be removed as soon as we have unique urls per image revision.
            return `${this.course.preview}?t=${this.course.updated_at?.getTime()}`;
        },
    },

    methods: {
        trans,

        /**
         * Refresh the UI
         */
        refresh() {
            // Force re-render of the component:
            this.renderTimestamp = (new Date()).toString();
            return this;
        },

        /**
         * Change handler for preview image
         */
        onChangePreviewImage(data) {
            this.course.preview = data.result;
            this.course.preview_thumbnail = data.result;
            this.course.previewImageForUpload = (data.files instanceof FileList && data.files.length >= 1) ?
                data.files[0] : null;
            this.$emit('change', this.course);
        },

        /**
         * Change handler for properties
         */
        onChangeProperty() {
            this.$emit('change', this.course);
        },
    }
});
</script>

<style lang="scss" scoped>

</style>
