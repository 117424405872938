<template>
    <div class="launch-page">

        <PageLmsXApiLaunchHeader
            :help-url="helpUrl"/>

        <PageLmsXApiLaunchStage
            :teaser-headline="trans('lms_xapi.launch_page.teaser.headline')"
            :teaser-copy="trans('lms_xapi.launch_page.teaser.copy')"
            :card-image-url="unitPreviewUrl"
            :card-headline="unitTitle"
            :card-text="unitDescription"
            teaser-button-caption="lms_xapi.launch_page.teaser.button"
            @onTeaserButtonClicked="onInstallationClick"
        />

        <section class="launch-page-credentials launch-page-container">
            <h2>{{ trans('lms_xapi.launch_page.credentials.headline') }}</h2>
            <TextOutputField :text="url" icon="icon_server"/>
            <TextOutputField :text="userId" icon="icon_user"/>
            <TextOutputField :text="userPassword" icon="icon_lock_outline"/>
            <p class="info">
                <Icon name="icon_info"/>
                {{ trans('lms_xapi.launch_page.credentials.info') }}
            </p>
        </section>

        <div class="launch-page-row">
            <div class="launch-page-container launch-page-steps">
                <h2>{{ trans('lms_xapi.launch_page.steps.headline') }}</h2>

                <div class="launch-page-step">
                    <img class="launch-page-step-image" src="/images/lms/landing/getstarted-01_2x.png">
                    <div class="launch-page-step-content">
                        <h3>{{ trans('lms_xapi.launch_page.steps.1.headline') }}</h3>
                        <div v-html="trans('lms_xapi.launch_page.steps.1.copy')"/>
                    </div>
                </div>

                <div class="launch-page-step">
                    <img class="launch-page-step-image" src="/images/lms/landing/getstarted-02_2x.png">
                    <div class="launch-page-step-content">
                        <h3>{{ trans('lms_xapi.launch_page.steps.2.headline') }}</h3>
                        <p>{{ trans('lms_xapi.launch_page.steps.2.copy') }}</p>
                    </div>
                </div>

                <div class="launch-page-step">
                    <img class="launch-page-step-image" src="/images/lms/landing/getstarted-03_2x.png">
                    <div class="launch-page-step-content">
                        <h3>{{ trans('lms_xapi.launch_page.steps.3.headline') }}</h3>
                        <p>{{ trans('lms_xapi.launch_page.steps.3.copy') }}</p>
                    </div>
                </div>

                <div class="launch-page-step">
                    <img class="launch-page-step-image" src="/images/lms/landing/getstarted-04_2x.png">
                    <div class="launch-page-step-content">
                        <h3>{{ trans('lms_xapi.launch_page.steps.4.headline') }}</h3>
                        <p>{{ trans('lms_xapi.launch_page.steps.4.copy') }}</p>
                    </div>
                </div>

            </div>
        </div>

        <section class="launch-page-installation">
            <div class="launch-page-installation-stage">
                <div class="launch-page-installation-teaser">
                    <p>{{ trans('lms_xapi.launch_page.installation.headline') }}</p>
                    <h3>{{ trans('lms_xapi.launch_page.installation.copy') }}</h3>
                    <ButtonPrimary
                        @trigger="onInstallationClick"
                        caption="lms_xapi.launch_page.installation.button"
                    />
                </div>
                <img class="" src="/images/lms/landing/install-3spin-learning_2x.jpg"/>
            </div>
        </section>

        <PageLmsXApiLaunchFooter/>
    </div>
</template>

<script>
    import ButtonSecondary from "@/Vue/Common/ButtonSecondary";
    import Icon from "@/Vue/Common/Icon";
    import TextOutputField from "@/Vue/Common/TextOutputField";
    import ButtonPrimary from "@/Vue/Common/ButtonPrimary";
    import EventType from "@/Utility/EventType";
    import PageLmsXApiLaunchHeader from "@/Vue/Lms/xapi/PageLmsXApiLaunchHeader";
    import PageLmsXApiLaunchFooter from "@/Vue/Lms/xapi/PageLmsXApiLaunchFooter";
    import PageLmsXApiLaunchStage from "@/Vue/Lms/xapi/PageLmsXApiLaunchStage";

    export default {
        name: "PageLmsXApiLaunch",
        components: {
            ButtonPrimary,
            TextOutputField,
            Icon,
            ButtonSecondary,
            PageLmsXApiLaunchHeader,
            PageLmsXApiLaunchStage,
            PageLmsXApiLaunchFooter,
        },
        props: {
            unitTitle: {
                type: String,
            },
            unitDescription: {
                type: String,
            },
            unitPreviewUrl: {
                type: String,
            },
            url: {
                type: String,
            },
            userEmail: {
                type: String,
            },
            userPassword: {
                type: String,
            },
            helpUrl: {
                type: String,
            },
        },

        computed: {

            /**
             * @return {string}
             */
            userId() {
                return this.userEmail.split('@')[0];
            }

        },

        methods: {

            onInstallationClick() {
                this.$globalEvents.emit(EventType.MODAL_INSTALL_APP_SHOW);
            }

        },
    }
</script>

<style lang="scss">

    $xapi-launch-page-margin: 24px;
    $xapi-launch-page-max-width: 1200px;
    $xapi-launch-min-column-width: 350px;
    $xapi-launch-column-gap: 24px 48px;

    .launch-page {
        width: 100%;
        background-color: var(--background-color-white);
        overflow: auto;

        p, li {
            font-family: var(--font-family-condensed);
        }

        ul {
            margin-bottom: 1rem;
            list-style-position: inside;
        }

        p + button {
            margin-top: 16px;
        }

        .text-output-field {
            max-width: 400px;
            font-family: var(--font-family-mono-bold);
            font-size: var(--font-size-default);
        }

        .bg-color-primary {
            background-color: var(--color-primary);
        }

        .launch-page-row {
            display: flex;
            flex-wrap: wrap;
        }

        .launch-page-container {
            width: 100%;
            max-width: $xapi-launch-page-max-width;
            display: flex;
            margin: 0 auto;
            padding: 0 $xapi-launch-page-margin 0 $xapi-launch-page-margin;
        }

        .launch-page-credentials {
            display: block;
            margin-top: 24px;

            h4 {
                margin-bottom: 16px;
            }

            .info {
                display: flex;
                align-items: center;
                gap: 4px;
                font-family: var(--font-family-condensed-demibold);
                color: var(--color-anthracite40);
                margin-top: 16px;

                .icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .launch-page-steps {
            flex-direction: column;
            margin-top: 40px;
            margin-bottom: 60px;
            gap: 32px;

            h4 {
                margin-bottom: 0;
            }
        }

        .launch-page-step {
            display: flex;
            flex-wrap: wrap-reverse;
            align-items: flex-end;
            gap: $xapi-launch-column-gap;

            .launch-page-step-image {
                flex: 1 1 $xapi-launch-min-column-width;
                width: 100%;
                max-width: 400px;
                border-radius: var(--card-border-radius);
                box-shadow: var(--card-box-shadow);
            }

            .launch-page-step-content {
                flex: 1 $xapi-launch-min-column-width;
                max-width: 400px;

                & > ul {
                    padding-left: 20px;
                    margin-bottom: 1rem;
                }

                & > *:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .w-100 {
            width: 100%;
        }

        .launch-unit-card {
            display: flex;
            flex-direction: column;
            flex: 1 1 $xapi-launch-min-column-width;
            max-width: 500px;
            border-radius: var(--card-border-radius);
            background-color: var(--background-color-white);
            box-shadow: var(--card-box-shadow);
            padding: 12px 12px 16px 12px;

            img {
                border-radius: var(--card-border-radius-small);
                width: 100%;

                &:only-child {
                    margin-bottom: -4px;
                }
            }

            .launch-unit-card-body {
                display: flex;
                flex-direction: column;
                margin: 0 11px;
                padding-top: 16px;

                h3 {
                    margin-bottom: 4px;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-bottom: 0;
                }
            }
        }

        .launch-page-installation {
            background-color: var(--background-color-light);

            .launch-page-installation-stage {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                max-width: $xapi-launch-page-max-width;
                margin: 0 auto;
                padding: 32px $xapi-launch-page-margin;
                gap: $xapi-launch-column-gap;

                & > * {
                    max-width: 500px;
                    flex: 1 $xapi-launch-min-column-width;
                }

                .launch-page-installation-teaser {
                    .btn {
                        margin-top: 20px;
                    }
                }

                img {
                    border-radius: var(--card-border-radius);
                    flex-shrink: 1;
                    width: 100%;
                }
            }
        }

        footer .footer-stage {
            display: flex;
            justify-content: flex-end;
            padding: 32px 0;
        }
    }

</style>
