import { trans } from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';
import {
    UnitPermissionPolicySample,
    UnitPermissionPolicyStandard,
    UnitPermissionPolicyTemplate
} from "@/Models/Unit/UnitPermissionPolicy";
import MultiFilterCategory from '@/Filters/MultiFilterCategory.js';
import FilterSection from '@/Filters/FilterSection.js';

/**
 * Filter definitions
 */
const FilterDefinitions = {

    Draft: {
        title: trans('labels.draft'),
        callback(elements, options = null) {
            return elements.filter(e => e.isDraft);
        },
        paramName: 'draft',
    },

    Released: {
        title: trans('labels.released'),
        callback(elements, options = null) {
            return elements.filter(e => e.isReleased);
        },
        paramName: 'released',
    },

    HasUnreleasedChanges: {
        title: trans('labels.unreleased_changes'),
        callback(elements, options = null) {
            return elements.filter(e => e.hasUnreleasedChanges);
        },
        paramName: 'unreleased_changes',
    },

    PolicyStandard: {
        title: trans('labels.my_library'),
        callback(elements, options = null) {
            return elements.filter(e => e.policy === UnitPermissionPolicyStandard.type);
        },
        paramName: 'standard',
    },

    FreeLibrary: {
        title: trans('labels.free_library'),
        callback(elements, options = null) {
            return elements.filter(e => [UnitPermissionPolicySample.type, UnitPermissionPolicyTemplate.type].includes(e.policy));
        },
        paramName: [
            'sample',
            'template',
        ],
    },
};

export default class UnitFilters
{
    static get Draft()                  { return new FilterCategory(FilterDefinitions.Draft); }
    static get Released()               { return new FilterCategory(FilterDefinitions.Released); }
    static get HasUnreleasedChanges()   { return new FilterCategory(FilterDefinitions.HasUnreleasedChanges); }

    static get PolicyStandard()         { return new FilterCategory(FilterDefinitions.PolicyStandard); }

    static get FreeLibrary()            { return new FilterCategory(FilterDefinitions.FreeLibrary); }

    static get EditCourseAll() {
        return new MultiFilterCategory(
            trans('labels.all'),
            [
                new FilterSection(
                    trans('labels.my_library'),
                    FilterDefinitions.PolicyStandard
                ),
                new FilterSection(
                    trans('labels.free_library'),
                    FilterDefinitions.FreeLibrary
                ),
            ]
        );
    }

    constructor()
    {
        if (this.constructor === UnitFilters)
        {
            throw new TypeError('Static class "UnitFilters" cannot be instantiated directly.');
        }
    }
}
