import VariableOperation, {
    BoolSetOperation,
    NumberAddOperation,
    NumberMultiplyOperation,
    NumberSetOperation,
    NumberSubtractOperation,
} from '@/Models/UnitData/Variables/VariableOperation';
import Operand, {
    BooleanValueOperand,
    ToggledValueOperand,
    NumberValueOperand,
    InitialValueOperand,
    ReferenceValueOperand,
} from '@/Models/UnitData/Variables/Operand';
import VariableComparison, {
    BoolEqualsComparison,
    NumberEqualsComparison,
    NumberGreaterOrEqualsComparison,
    NumberLessOrEqualsComparison,
} from '@/Models/UnitData/Variables/VariableComparison';
import DropdownOption from '@/Utility/DropdownOption';
import DropdownOptionGroup from '@/Utility/DropdownOptionGroup';
import {trans} from '@/Utility/Helpers';
import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
import SceneObject from "@/Models/UnitData/SceneObjects/SceneObject";
import UnitData from "@/Models/UnitData/UnitData";
import BoolVariable from '@/Models/UnitData/Variables/Variables/BoolVariable';
import NumberVariable from '@/Models/UnitData/Variables/Variables/NumberVariable';

/**
 * @param {Variable<any>} variable
 * @returns {String} Component name for the variable input.
 */
export function getVariableComponentName(variable) {
    const mapping = new Map([
        [BoolVariable.Type, 'PanelVariableBool'],
        [NumberVariable.Type, 'PanelVariableNumber'],
    ]);

    if (!mapping.has(variable.type)) {
        throw new Error(`Variable type "${variable.type}" not mapped to its component name!`);
    }

    return mapping.get(variable.type);
}

/**
 * @param {VariableOperation} operation
 * @returns {String|null} Component name for selecting the operand of the given operation or null if no component is needed.
 */
export function getOperandComponentNameFromOperation(operation) {
    const operationComponentMappings = new Map([
        [BoolSetOperation.Type, null],
        [NumberAddOperation.Type, 'PanelVariableOperandNumber'],
        [NumberMultiplyOperation.Type, 'PanelVariableOperandNumber'],
        [NumberSetOperation.Type, 'PanelVariableOperandNumber'],
        [NumberSubtractOperation.Type, 'PanelVariableOperandNumber'],
    ]);

    if (!operationComponentMappings.has(operation.type)) {
        throw new Error(`Operation type "${operation.type}" not mapped to its component name!`);
    }

    return operationComponentMappings.get(operation.type);
}

/**
 * @param {VariableComparison} comparison
 * @returns {String|null} Component name for selecting the operand of the given comparison or null if no component is needed.
 */
export function getOperandComponentNameFromComparison(comparison) {
    const comparisonComponentMappings = new Map([
        [BoolEqualsComparison.Type, null],
        [NumberEqualsComparison.Type, 'PanelVariableOperandNumber'],
        [NumberGreaterOrEqualsComparison.Type, 'PanelVariableOperandNumber'],
        [NumberLessOrEqualsComparison.Type, 'PanelVariableOperandNumber'],
    ]);

    if (!comparisonComponentMappings.has(comparison.type)) {
        throw new Error(`Comparison type "${comparison.type}" not mapped to its component name! Return null to not display any component`);
    }

    return comparisonComponentMappings.get(comparison.type);
}

/**
 * @returns {DropdownOption[]} Dropdown options for bool variables
 */
export function getTargetOptionsForBoolVariable() {
    return [true, false].map(bool => new DropdownOption({
        caption: trans('variables.values.' + bool).toUpperCase(),
        value: bool,
    }));
}

/**
 * @param {String} variableType
 * @return {VariableOperation} Default operation for this kind of variable.
 */
export function defaultOperationForVariableType(variableType) {
    switch (variableType) {
        case NumberVariable.Type:
            return VariableOperation.createWithType(NumberSetOperation.Type, {}, null);
        case BoolVariable.Type:
            return VariableOperation.createWithType(BoolSetOperation.Type, {}, null);
        default:
            throw new Error(`No default operation defined for variable of type "${variableType}".`);
    }
}

/**
 * @param variableType
 * @return {Class<VariableOperation>[]} All allowed operation classes for the given variable type.
 */
export function getAvailableOperationClassesForVariableOfType(variableType) {
    switch (variableType) {
        case NumberVariable.Type:
            return [
                NumberAddOperation,
                NumberSubtractOperation,
                NumberMultiplyOperation,
                NumberSetOperation,
            ];
        case BoolVariable.Type:
            return [
                BoolSetOperation,
            ];
        default:
            throw new Error(`Operations for variables of type "${variableType}" not defined.`);
    }
}

/**
 * @param {Command} command
 * @param {String} variableType
 * @return {DropdownOption[]} All operation DropdownOption available for the given variable type.
 */
export function getAvailableOperandOptionsForVariableOfType(command, variableType) {

    const options = [];
    const variableOptions = dropdownOptionsForVariablesWithVariableType(command, variableType);
    switch (variableType) {
        case NumberVariable.Type:

            options[options.length] = new DropdownOption({
                caption: trans('variables.operands.number'),
                value: Operand.createWithType(NumberValueOperand.Type, {value: 0}, null)
            });

            options[options.length] = new DropdownOption({
                caption: trans('variables.operands.initial'),
                value: Operand.createWithType(InitialValueOperand.Type, {}, null)
            });

            return options.concat(variableOptions);

        case BoolVariable.Type:

            options[options.length] = new DropdownOption({
                caption: trans('variables.operands.true'),
                value: Operand.createWithType(BooleanValueOperand.Type, {value: true}, null)
            });

            options[options.length] = new DropdownOption({
                caption: trans('variables.operands.false'),
                value: Operand.createWithType(BooleanValueOperand.Type, {value: false}, null)
            });

            if(command.commandType.type !== 'condition'){

                options[options.length] = new DropdownOption({
                    caption: trans('variables.operands.toggled'),
                    value: Operand.createWithType(ToggledValueOperand.Type, {}, null)
                });
            }

            options[options.length] = new DropdownOption({
                caption: trans('variables.operands.initial'),
                value: Operand.createWithType(InitialValueOperand.Type, {}, null)
            });

            return options.concat(variableOptions);

        default:
            throw new Error('Operation Operands for Variable of Type "${variableType}" not defined.');
    }
}

/**
 * @param {String} variableType
 * @returns {DropdownOption[]} All operation DropdownOption available for the given variable type.
 */
export function getAvailableOperationOptionsForVariableOfType(variableType) {
    return getAvailableOperationClassesForVariableOfType(variableType)
        .map(operationClass => new DropdownOption({
                caption: trans('variables.operations.' + operationClass.Type + '.caption'),
                title: trans('variables.operations.' + operationClass.Type + '.title'),
                value: operationClass.Type
            })
        );
}

/**
 * @param {String} variableType
 * @return {VariableComparison} Default comparison for this kind of variable.
 */
export function defaultComparisonForVariableType(variableType) {
    switch (variableType) {
        case NumberVariable.Type:
            return VariableComparison.createWithType(NumberEqualsComparison.Type, {}, null);
        case BoolVariable.Type:
            return VariableComparison.createWithType(BoolEqualsComparison.Type, {}, null);
        default:
            throw new Error(`No default comparison defined for variable of type "${variableType}".`);
    }
}

/**
 * @param variableType
 * @return {Class<VariableComparison>[]} All allowed comparison classes for the given variable type.
 */
export function getAvailableComparisonClassesForVariableOfType(variableType) {
    switch (variableType) {
        case NumberVariable.Type:
            return [
                NumberEqualsComparison,
                NumberGreaterOrEqualsComparison,
                NumberLessOrEqualsComparison,
            ];
        case BoolVariable.Type:
            return [
                BoolEqualsComparison,
            ];
        default:
            throw new Error(`Comparisons for variables of type "${variableType}" not defined.`);
    }
}

/**
 * @param {String} variableType
 * @returns {DropdownOption[]} All comparison DropdownOption available for the given variable type.
 */
export function getAvailableComparisonOptionsForVariableOfType(variableType) {
    return getAvailableComparisonClassesForVariableOfType(variableType)
        .map(comparisonClass => new DropdownOption({
                caption: trans('variables.comparisons.' + comparisonClass.Type + ".caption"),
                title: trans('variables.comparisons.' + comparisonClass.Type + ".title"),
                value: comparisonClass.Type
            })
        );
}

/**
 * Find a specific variable object inside the given unitData.
 *
 * @param {UnitData} unitData unitData to search inside
 * @param {String} objectUid uid of the parent scene object of the variable we are looking for
 * @param {string} variableUid uid of the variable we are looking for
 * @returns {Variable|null} null, if no variable with these parameters could be found
 */
export function getVariable(unitData, objectUid, variableUid) {
    const variableModule = filterVariablesOfSceneObjects(unitData.allSceneObjects)
        .find(module => module.uid === objectUid);

    if (variableModule === undefined) {
        return null;
    }

    return variableModule.getVariable(variableUid);
}

/**
 * Find the parent variable module of the given variable inside the given unitData.
 *
 * @param {UnitData} unitData unitData to search inside
 * @param {string} variableUid uid of the variable we are looking for
 * @returns {string|null} uid of the variable module or null if no module with these parameters could be found
 */
export function getVariableModuleUid(unitData, variableUid) {
    const variableModules = filterVariablesOfSceneObjects(unitData.allSceneObjects);

    const variableModule = variableModules
        .find(module => module.hasVariable(variableUid));

    return variableModule === undefined ? null : variableModule.uid;
}

/**
 * Get options for selecting a variable from the given variable module.
 * All variables of this module will be grouped.
 *
 * @param {SceneObjectModuleVariable} variableModule
 * @returns {DropdownOptionGroup}
 */
function dropdownOptionGroupForVariableModule(variableModule) {
    return new DropdownOptionGroup({
        caption: variableModule.title,
        //icon: 'icon_variable',
        isSeparator: false,
        collapsible: true,
        options: variableModule.variables.map(variable => new DropdownOption({
            caption: (variable.name === null || variable.name === '') ? trans('variables.' + variable.type) : variable.name,
            value: variable.uid,
            referenceUid: variableModule.uid,
        })),
        referenceUid: variableModule.uid,
    });
}

/**
 * Get options for selected variable module with variables of specific type
 * All variables with given type of this module will be grouped.
 *
 * @param {SceneObjectModuleVariable} variableModule
 * @param {string} variableType
 * @returns {DropdownOptionGroup | null}
 */
function dropdownOptionGroupForVariableModuleForVariablesWithType(variableModule, variableType) {

    const variables = variableModule.variables.filter( variable => variable.type === variableType);
    if (variables.length < 1) {
        return null;
    }

    return new DropdownOptionGroup({
        caption: variableModule.title,
        //icon: 'icon_variable',
        isSeparator: true,
        collapsible: true,
        options: variables.map(variable => new DropdownOption({
            caption: (variable.name === null || variable.name === '') ? trans('variables.' + variable.type) : variable.name,
            value: Operand.createWithType(ReferenceValueOperand.Type, {
                variable: variable.uid,
                object: variableModule.uid
            }, null),
            referenceUid: variableModule.uid,
        })),
        referenceUid: variableModule.uid,
    });
}

/**
 * Get options for selecting a variable in any variable module of the same scene or
 * globally. Variable options are grouped by their modules.
 *
 * @param {Command} command
 * @param {string} variableType
 * @returns {DropdownOption[]}
 */
export function dropdownOptionsForVariablesWithVariableType(command, variableType) {

    const unitData = command.getParent(UnitData);
    const currentScene = command.getParent(TrainingScene);
    const sceneObject = command.getParent(SceneObject);
    const options = [];

    if (sceneObject !== null) {
        // Check if own object is a SceneObjectModuleVariable
        const selfTargetObjects = filterVariablesOfSceneObjects([sceneObject]);
        if (selfTargetObjects.length === 1) {
            const option = dropdownOptionGroupForVariableModuleForVariablesWithType(selfTargetObjects[0], variableType);
            if (option !== null) {
                options[options.length] = option;
            }
        }
    }

    // Filter global objects for variable modules and create dropdown-groups
    if (unitData.hasObjects) {
        const matchingGlobalObjects = filterVariablesOfSceneObjects(unitData.allGlobalObjects);
        if (matchingGlobalObjects.length > 0) {
            matchingGlobalObjects
                .filter(o => sceneObject === null || o.uid !== sceneObject.uid)
                .forEach(o => {
                    const option = dropdownOptionGroupForVariableModuleForVariablesWithType(o, variableType);
                    if (option !== null) {
                        options[options.length] = option;
                    }
                });
        }
    }

    // Filter its scene objects for variable modules and create dropdown-groups
    const scenes = (sceneObject && sceneObject.isGlobal === true) ? unitData.scenes : (currentScene !== null ? [currentScene] : []);
    if (scenes.length > 0) {
        scenes
            .filter(s => s.hasObjects)
            .forEach(s => {
                const matchingSceneObjects = filterVariablesOfSceneObjects(s.allSceneObjects);
                if (matchingSceneObjects.length > 0) {
                    matchingSceneObjects
                        .filter(o => sceneObject === null || o.uid !== sceneObject.uid)
                        .forEach(o => {
                            const option = dropdownOptionGroupForVariableModuleForVariablesWithType(o, variableType);
                            if (option !== null) {
                                options[options.length] = option;
                            }
                        });
                }
            });
    }

    return options;
}

/**
 * Get options for selecting a variable in any variable module of the same scene or
 * globally. Variable options are grouped by their modules.
 *
 * @param {Command} command
 * @returns {DropdownOption[]}
 */
export function dropdownOptionsForVariables(command) {

    const unitData = command.getParent(UnitData);
    const currentScene = command.getParent(TrainingScene);
    const sceneObject = command.getParent(SceneObject);
    const options = [];

    if (sceneObject !== null) {
        // Check if own object is a SceneObjectModuleVariable
        const selfTargetObjects = filterVariablesOfSceneObjects([sceneObject]);
        if (selfTargetObjects.length === 1 && selfTargetObjects[0].hasVariables)
        {
            const groupForSelfTarget = dropdownOptionGroupForVariableModule(selfTargetObjects[0]);
            // Change caption to "This Module" and set separator style:
            groupForSelfTarget.caption = trans('authoring.target_this_module');
            groupForSelfTarget.isSeparator = true;
            options[options.length] = groupForSelfTarget;
        }
    }

    // Filter global objects for variable modules and create dropdown-groups
    if (unitData.hasObjects) {
        const matchingGlobalObjects = filterVariablesOfSceneObjects(unitData.allGlobalObjects);
        if (matchingGlobalObjects.length > 0) {

            matchingGlobalObjects
                .filter(o => sceneObject === null || o.uid !== sceneObject.uid)
                .forEach((o, i) => {
                    if (i === 0)
                    {
                        options[options.length] = new DropdownOption({
                            isSeparator: true,
                            caption: trans('variables.global_variables'),
                            //icon: 'icon_variable'
                        });
                    }
                    options[options.length] = dropdownOptionGroupForVariableModule(o);
                });
        }
    }

    // Filter its scene objects for variable modules and create dropdown-groups
    const scenes = (sceneObject && sceneObject.isGlobal === true) ? unitData.scenes : (currentScene !== null ? [currentScene] : []);
    if (scenes.length > 0) {
        scenes
            .filter(s => s.hasObjects)
            .forEach(s => {
                const matchingSceneObjects = filterVariablesOfSceneObjects(s.allSceneObjects);
                if (matchingSceneObjects.length > 0) {

                    matchingSceneObjects
                        .filter(o => sceneObject === null || o.uid !== sceneObject.uid)
                        .forEach((o, i) => {
                            if (i === 0)
                            {
                                options[options.length] = new DropdownOption({
                                    isSeparator: true,
                                    caption: s.title,
                                    //icon: 'icon_variable'
                                });
                            }
                            options[options.length] = dropdownOptionGroupForVariableModule(o);
                        });
                }
            });
    }

    return options;
}

/**
 * Filters the list of given SceneObjects for variables.
 *
 * @param {SceneObject[]} sceneObjects
 * @returns {SceneObjectModuleVariable[]}
 */
function filterVariablesOfSceneObjects(sceneObjects) {
    return sceneObjects.filter(o => o.type === 'widget' && o.subtype === 'variable');
}
