<template>
    <div
        id="inspector"
        v-not-focusable
        v-shortcuts
        class="inspector-content"
    >
        <header :class="'inspector-header module module-' + sceneObject.subtype">
            <Icon class="inspector-panel-header-icon" name="icon_helper" />
            {{ trans('labels.inspector.module_properties') }}
        </header>
        <div :key="'InspectorPanels' + refreshUI + sceneObject.uid" v-not-focusable class="panels">
            <div :class="'inspector-panel panel-module module-' + sceneObject.subtype">

                <div class="preview-image">
                    <img :src="sceneObject.sceneObjectType?.preview" alt="Helper preview image">
                </div>

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :error-msg="trans('errors.module.title')"
                        :maxlength="50"
                        :model="sceneObject"
                        :placeholder="trans('authoring.module_title_placeholder')"
                        :required="true"
                        property="title"
                        @change="onChangeProperty"
                    />
                </div>

                <Collapsible :key="'helper_basics_' + sceneObject.uid + '_collapsible'">
                    <template #header>
                        {{ trans('labels.basics') }}
                    </template>
                    <template #body>
                        <!-- Visibility state -->
                        <div v-if="!sceneObject.isGlobal" class="property property-visibility">
                            <Checkbox
                                :caption="trans('authoring.activate_when_scene_loaded')"
                                :model="sceneObject"
                                layout="checkbox-right"
                                property="visible"
                                @change="onChangeProperty"
                            />
                        </div>

                        <SceneObjectPropertyLogoImage
                            :scene-object="sceneObject"
                            @change="onChangeProperty"
                        />

                        <!-- Glow color for Helper Module -->
                        <ColorHexInput
                            :label="trans('authoring.glow_color')"
                            :model="sceneObject"
                            class="property property-glowcolor"
                            property="glow_color"
                            @change="onChangeProperty"
                        />
                    </template>
                </Collapsible>
            </div>

            <PanelAI
                :knowledge-placeholder="trans('authoring.ai.knowledge.placeholder_helper')"
                :scene-object="sceneObject"
                @change="onChangeProperty"
            />

            <PanelTriggers
                :key="'panel-triggers-'+sceneObject.uid"
                :scene-object="sceneObject"
                @change="onChangeTriggers"
            />

            <!-- Collapsible triggers for reactions -->
            <PanelTriggers
                :key="'panel-triggers-reactions-'+sceneObject.uid"
                :scene-object="sceneObject"
                :show-as-reactions="true"
                @change="onChangeTriggers"
            />

            <PanelHintAssignment
                :key="'panel-hint-assignment-'+sceneObject.uid"
                :scene-object="sceneObject"
                @change="onChangeProperty"
            />

            <PanelWaypoints
                :key="'panel-waypoints-'+sceneObject.uid"
                :scene-object="sceneObject"
                @change="onChangeProperty"
            />
        </div>

        <div class="buttons">
            <!-- Btn: Duplicate -->
            <ButtonCustom
                v-tooltip="'buttons.authoring.module.duplicate'"
                :disabled="!canDuplicateSceneObject"
                caption="labels.duplicate"
                icon="icon_duplicate"
                @trigger="onClickDuplicateSceneObject"
            />

            <!-- Btn: Remove -->
            <ButtonCustom
                v-tooltip="'buttons.authoring.module.delete'"
                caption="labels.remove"
                icon="icon_delete"
                @trigger="onClickRemoveFromSelectedScene"
            />
        </div>

        <!-- Modal dialog for module remove confirmation -->
        <ModalApplyCancel
            :description="modalRemoveDescriptionText"
            :title="trans('modals.remove_helper_module.title')"
            event-type="MODAL_REMOVE_MODULE_FROM_SCENE"
        />
    </div>
</template>

<script lang="ts">
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import PanelHintAssignment from '@/Vue/Inspector/PanelHintAssignment.vue';
import PanelTriggers from '@/Vue/Inspector/PanelTriggers.vue';
import PanelWaypoints from '@/Vue/Inspector/PanelWaypoints.vue';
import SceneObjectPropertyLogoImage from '@/Vue/Inspector/Properties/SceneObjectPropertyLogoImage.vue';
import Icon from '@/Vue/Common/Icon.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import Collapsible from '@/Vue/Common/Collapsible.vue';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import ColorHexInput from '@/Vue/Common/ColorHexInput.vue';
import ButtonCustom from '@/Vue/Common/ButtonCustom.vue';
import {shortId, trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import {SceneObjectModuleHelper} from '@/Models/UnitData/SceneObjects/SceneObject';
import {defineComponent} from 'vue';
import type Trigger from '@/Models/UnitData/Triggers/Trigger';
import PanelAI from '@/Vue/Inspector/AI/PanelAI.vue';

export default defineComponent({
    components: {
        PanelAI,
        ButtonCustom,
        ColorHexInput,
        Checkbox,
        Collapsible,
        TextInput,
        Icon,
        ModalApplyCancel,
        PanelHintAssignment,
        PanelTriggers,
        PanelWaypoints,
        SceneObjectPropertyLogoImage,
    },

    props: {
        sceneObject: {
            type: SceneObjectModuleHelper,
            default: null,
        }
    },

    emits: {
        'change': (_: SceneObjectModuleHelper) => true,
    },

    data() {
        return {
            refreshUI: shortId(),                   // Helper for refreshing UI elements
            shortcuts: new Map([
                ['Duplicate.global.prevent', this.onShortcutDuplicate],
                ['Delete.global', this.onShortcutDelete],
                ['Replace.global.prevent', null],
            ])
        };
    },

    computed: {
        canDuplicateSceneObject() {
            return !this.sceneObject.hasReachedMaxCount;
        },

        relatedCommands() {
            return this.sceneObject?.relatedCommands || [];
        },

        modalRemoveDescriptionText() {
            if (this.relatedCommands.length) {
                return trans(
                    'modals.remove_helper_module.description_with_count',
                    { count: this.relatedCommands.length }
                );
            }
            return trans('modals.remove_helper_module.description');
        }
    },

    methods: {
        trans,

        /**
         * Common property change handler
         */
        onChangeProperty() {
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Update the triggers on the scene object whenever a change happens
         *
         * @param triggers List of Trigger objects
         */
        onChangeTriggers(triggers: Trigger[]) {
            //console.log('Inspector: Triggers have been updated on SceneObject', this.sceneObject);
            this.$globalEvents.emit(EventType.INSPECTOR_SCENE_OBJECT_UPDATED, this.sceneObject);

            // Update scene objectives:
            this.$globalEvents.emit(EventType.INSPECTOR_TRIGGERS_UPDATED, this.sceneObject, triggers);
            return this;
        },

        /**
         * Click handler for scene object duplicate button
         */
        onClickDuplicateSceneObject() {
            this.$globalEvents.emit(EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.sceneObject);
            return this;
        },

        /**
         * Click handler for the remove button
         */
        onClickRemoveFromSelectedScene() {
            // Show modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_MODULE_FROM_SCENE_SHOW, this.sceneObject);
            return this;
        },

        onShortcutDelete(_: CustomEvent) {
            return this.onClickRemoveFromSelectedScene();
        },

        onShortcutDuplicate(_: CustomEvent) {
            // Duplicate selected scene object if allowed:
            return this.canDuplicateSceneObject ? this.onClickDuplicateSceneObject() : this;
        },
    }
});
</script>

<style lang="scss" scoped>

</style>
